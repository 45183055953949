import axios from 'axios';
import { auth } from './../../firebase.js';

export const handleDelete = async (id, getPublications, setNotificationMessage, setIsLoading) => {
  try {
    const confirmation = window.confirm('¿Estás seguro que deseas eliminar el anuncio?');
    if (confirmation) {
      setIsLoading(true);
      if (auth.currentUser) {
        const token = await auth.currentUser.getIdToken();
        await axios.delete(`https://us-central1-hormiga-631f9.cloudfunctions.net/deleteAnuncio`, {
          headers: {
            'Authorization': `Bearer ${token}`
          },
          data: { anuncioId: id },
        });
        setNotificationMessage('Publicación borrada');
        getPublications();
      } else {
        setNotificationMessage('No estás autenticado.');
      }
    }
  } catch (error) {
    console.error('Error borrando publicación:', error);
    setNotificationMessage('Hubo un error borrando la publicación.');
  } finally {
    setIsLoading(false);
  }
};
