import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { auth } from './../../firebase.js';
import './../../style/pages/notifications.scss';

const Notifications = () => {
    const [notifications, setNotifications] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [notificationMessage, setNotificationMessage] = useState('');
    const [user, setUser] = useState(null);

    useEffect(() => {
        const fetchNotifications = async (currentUser) => {
            setIsLoading(true);
            try {
                const token = await currentUser.getIdToken(true);

                const commentsResponse = await axios.get('https://us-central1-hormiga-631f9.cloudfunctions.net/getComentariosByUserAnuncios', {
                    headers: {
                        Authorization: `Bearer ${token}`
                    },
                    params: {
                        userId: currentUser.uid
                    }
                });

                const formattedComments = commentsResponse.data.map(comment => {
                    const createdAt = new Date(comment.createdAt._seconds * 1000);
                    const sentAt = createdAt.toLocaleString();

                    return {
                        ...comment,
                        sentAt,
                        type: 'comment',
                        postId: comment.anuncioId,
                        viewCount: comment.viewCount || 0,
                        id: comment.id
                    };
                });

                formattedComments.sort((a, b) => new Date(b.sentAt) - new Date(a.sentAt));

                setNotifications(formattedComments);
            } catch (error) {
                console.error('Error fetching notifications:', error.response?.data || error.message);
                setNotificationMessage(`Error al obtener las notificaciones: ${error.response?.data?.error || 'Error desconocido'}`);
            } finally {
                setIsLoading(false);
            }
        };

        const unsubscribeAuth = auth.onAuthStateChanged(async (currentUser) => {
            if (currentUser) {
                setUser(currentUser);
                await fetchNotifications(currentUser);
            } else {
                setUser(null);
                setIsLoading(false);
                setNotificationMessage('Por favor, inicia sesión para ver las notificaciones.');
            }
        });

        return () => unsubscribeAuth();
    }, []);

    const handleNotificationClick = (notification) => {
        if (notification.type === 'comment') {
            window.location.href = `https://www.hormigadapp.com/publications-list/${notification.postId}`;
        }
    };

    return (
        <div className="notifications-container">
            {isLoading ? (
                <div className="loading-animation">
                    <div className="spinner"></div>
                </div>
            ) : (
                <div className="notifications-content">
                    <h1 className="notifications-title">Comentarios en tus Anuncios</h1>
                    {notificationMessage && (
                        <div className="alert" onClick={() => setNotificationMessage('')}>
                            {notificationMessage}
                        </div>
                    )}
                    {user && (
                        <div className="notifications-list">
                            {notifications.length === 0 ? (
                                <p>No tienes notificaciones.</p>
                            ) : (
                                notifications.map((notification, index) => (
                                    <div
                                        key={index}
                                        className={'notification-item'}
                                        onClick={() => handleNotificationClick(notification)}
                                    >
                                        <div className="notification-header">
                                            <strong>{notification.userName}</strong>
                                            <small> Creado el {notification.sentAt}</small>
                                        </div>
                                        <div className="notification-body">
                                            Ha comentado: <p>{notification.text}</p>
                                        </div>
                                    </div>
                                ))
                            )}
                        </div>
                    )}
                </div>
            )}
        </div>
    );
};

export default Notifications;
