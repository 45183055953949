import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { auth } from './../../firebase.js';
import StarRatingButtons from './../starsUser/index.js';
import StarRating from '../profile/starRatings.js';
import Viculado from './../../assets/images/enlace.png';
import Abrir from './../../assets/images/abierto.png';
import "../../style/components/publications.scss";

import { handleLikeAnuncio } from '../PublicationsList/handleLikeAnuncio.js';
import { handleLikeComentario } from '../PublicationsList/handleLikeComentario.js';
import { fetchComments } from '../PublicationsList/fetchComments.js';
import { handleComment } from '../PublicationsList/handleComment.js';
import { handleDelete } from '../PublicationsList/handleDelete.js';
import { handleDeleteComment } from '../PublicationsList/handleDeleteComment.js';
import { fetchUserAds } from '../PublicationsList/fetchUserAds.js';
import { fetchAdDetails } from '../PublicationsList/fetchAdDetails.js';
import { handleSendMessage } from '../PublicationsList/handleSendMessage.js';

function AdDetails() {
  const { id } = useParams();
  const [adDetails, setAdDetails] = useState(null);
  const [comments, setComments] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [commentText, setCommentText] = useState('');
  const [notificationMessage, setNotificationMessage] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [showComments, setShowComments] = useState(false);
  const [currentUserId, setCurrentUserId] = useState(null);
  const [userAds, setUserAds] = useState([]);
  const [isCopying, setIsCopying] = useState(false);
  const [showRatingButtons, setShowRatingButtons] = useState(false);
  const [showPhoneNumber, setShowPhoneNumber] = useState(false);

  const togglePhoneVisibility = () => setShowPhoneNumber(!showPhoneNumber);
  const toggleRatingButtonsVisibility = () => setShowRatingButtons(!showRatingButtons);

  const copyToClipboard = (id) => {
    if (isCopying) return;
    const url = `https://www.hormigadapp.com/publications-list/${id}`;
    setIsCopying(true);
    navigator.clipboard.writeText(url).then(() => {
      setNotificationMessage('Enlace copiado');
      setTimeout(() => setIsCopying(false), 3000);
    }).catch((error) => {
      console.error('Error al copiar el enlace: ', error);
      setIsCopying(false);
    });
  };

  const openLinkInNewTab = (id) => {
    const url = `https://www.hormigadapp.com/publications-list/${id}`;
    window.open(url, '_blank', 'noopener,noreferrer');
  };

  useEffect(() => {
    const fetchCurrentUser = async () => {
      auth.onAuthStateChanged((user) => {
        if (user) {
          setCurrentUserId(user.uid);
        }
      });
    };
    fetchCurrentUser();
    fetchAdDetails(id, setAdDetails, setIsLoading, setNotificationMessage, (adId) => fetchComments(adId, setComments, setIsLoading));
  }, [id]);

  const sendMessage = async () => {
    setIsSubmitting(true);
    await handleSendMessage(adDetails.userId, commentText, setNotificationMessage, setIsLoading, setIsSubmitting);
    setCommentText(''); // Limpiar el input después de enviar el mensaje
  };

  return (
    <div>
      {isLoading ? (
        <div className="loading-animation">
          <div className="spinner"></div>
        </div>
      ) : (
        adDetails && (
          <div className="card">
            <div className='user-date'>
              <span>
                Usuario: <span style={{ color: '#F0B90B' }}>{adDetails.userName} <StarRating rating={parseFloat(adDetails.averageRating) || 0} /></span>
                <br />
                Ha recibido{' '}{adDetails.ratingCount || 'N/A'}{' '}{adDetails.ratingCount === 1 ? 'calificación' : 'calificaciones'} y su puntaje promedio es de{' '}
                {adDetails.averageRating ? adDetails.averageRating.toFixed(1) : 'N/A'}
                <br />
                <button className='buton-telefono' onClick={toggleRatingButtonsVisibility}>Calificar</button>
                {showRatingButtons && <StarRatingButtons userId={adDetails.userId} />}
              </span>
              <br />
              <span>
                <span style={{ color: '#00bfff' }}>{showPhoneNumber ? adDetails.phone : ""}</span>
                <button className='buton-telefono' onClick={togglePhoneVisibility}>Ver teléfono</button>
              </span>
              <div className='user-travel'>
                <span>Origen: {adDetails.city}, {adDetails.province}, {adDetails.country}</span>
                <span>Destino: {adDetails.destinationCity}, {adDetails.destinationProvince}, {adDetails.destinationCountry}</span>
                <span>Salida: {adDetails.departureDate}</span>
                <span>Llegada: {adDetails.arrivalDate}</span>
                <span>Descripción: {adDetails.descriptionItem}</span>
                <span>Valor de carga max: <span style={{ color: '#00bfff' }}>{adDetails.maxLoadValue}</span></span>
                <span>Recompensa: <span style={{ color: '#85bb65' }}>{adDetails.reward}</span></span>
                <span>Acción: <span style={{ color: '#F0B90B' }}>{adDetails.option}</span></span>
              </div>
            </div>
            <div className="interaction-buttons">
              <button className='like-button' onClick={() => handleLikeAnuncio(adDetails.id, true, adDetails, setAdDetails, setNotificationMessage)}>👍 {adDetails.likes}</button>
              <button className='dislike-button' onClick={() => handleLikeAnuncio(adDetails.id, false, adDetails, setAdDetails, setNotificationMessage)}>👎 {adDetails.dislikes}</button>
            </div>
            {notificationMessage && <div className="alert" onClick={() => setNotificationMessage('')}>{notificationMessage}</div>}
            <div className="button-container">
              <button className="button2" onClick={() => {
                setShowComments(!showComments);
                if (!showComments) fetchComments(id, setComments, setIsLoading); // Fetch comments when showing
              }}>
                {showComments ? 'Ocultar comentarios' : 'Mostrar comentarios'}
              </button>
              {currentUserId === adDetails.userId && (
                <button className='delete-button' onClick={() => handleDelete(adDetails.id, () => fetchAdDetails(id, setAdDetails, setIsLoading, setNotificationMessage, (adId) => fetchComments(adId, setComments, setIsLoading)), setNotificationMessage, setIsLoading)}>Eliminar Anuncio</button>
              )}
              <button className="button2" onClick={() => fetchUserAds(adDetails.userId, setUserAds, setIsLoading)}>Anuncios del usuario</button>
              <button className={`copy-enlace ${isCopying ? 'copying' : ''}`} onClick={() => copyToClipboard(adDetails.id)} title='Copiar enlace'>
                <img src={Viculado} alt="Copiar enlace" style={{ width: '25px', height: '25px' }} />
              </button>
            </div>
            {showComments && (
              <div className="comment-input-container">
                <div className="comment-section">
                  {(comments[id] || []).map((comment) => (
                    <div key={comment.id} className="comment">
                      <p className="comment-text"><strong style={{ color: '#F0B90B' }}>{comment.user}:</strong> {comment.text}</p>
                      <p className="comment-details"><small>Escrito el {comment.timestamp}</small></p>
                      <div className="interaction-buttons">
                        <button className='like-button' onClick={() => handleLikeComentario(adDetails.id, comment.id, true, setComments, setNotificationMessage)}>👍 {comment.likes}</button>
                        <button className='dislike-button' onClick={() => handleLikeComentario(adDetails.id, comment.id, false, setComments, setNotificationMessage)}>👎 {comment.dislikes}</button>
                      </div>
                      {comment.userId === currentUserId && (
                        <button className='delete-button' onClick={() => handleDeleteComment(adDetails.id, comment.id, setComments, setNotificationMessage, setIsLoading)}>Eliminar</button>
                      )}
                    </div>
                  ))}
                </div>
                <div className='chat-container'>
                  <textarea
                    className='input-comentarios'
                    type="text"
                    maxLength="300"
                    placeholder="Escribe aqui..."
                    value={commentText}
                    onChange={(e) => setCommentText(e.target.value)}
                    required
                    disabled={isSubmitting}
                  />
                  <button className='Send' onClick={() => handleComment(adDetails.id, commentText, setCommentText, fetchComments, setNotificationMessage, setIsSubmitting, setIsLoading)} disabled={isSubmitting}>
                    {isSubmitting ? 'Enviando...' : 'Comentar'}
                  </button>
                  <button className='Send' onClick={sendMessage} disabled={isSubmitting}>
                    {isSubmitting ? 'Enviando...' : 'Enviar Mensaje'}
                  </button>
                </div>
              </div>
            )}
            {userAds.length > 0 && (
              <div>
                <h2>Todos los anuncios del usuario</h2>
                {userAds.map(ad => (
                  <div key={ad.id}>
                    <div className='user-date'>
                      <div className='user-travel'>
                        <span>Origen: {ad.city}, {ad.province}, {ad.country}</span>
                        <span>Destino: {ad.destinationCity}, {ad.destinationProvince}, {ad.destinationCountry}</span>
                        <span>Salida: {ad.departureDate}</span>
                        <span>Llegada: {ad.arrivalDate}</span>
                        <span>Descripción: {ad.descriptionItem}</span>
                        <span>Valor de carga max: <span style={{ color: '#00bfff' }}>{ad.maxLoadValue}</span></span>
                        <span>Recompensa: <span style={{ color: '#85bb65' }}>{ad.reward}</span></span>
                        <span>Acción: <span style={{ color: '#F0B90B' }}>{ad.option}</span></span>
                      </div>
                      <br />
                      <div className="button-container">
                        {currentUserId === ad.userId && (
                          <button className='delete-button' onClick={() => handleDelete(ad.id, () => fetchUserAds(adDetails.userId, setUserAds, setIsLoading), setNotificationMessage, setIsLoading)}>Eliminar Anuncio</button>
                        )}
                        <button className={`copy-enlace ${isCopying ? 'copying' : ''}`} onClick={() => copyToClipboard(ad.id)} title='Copiar enlace'>
                          <img src={Viculado} alt="Copiar enlace" style={{ width: '25px', height: '25px' }} />
                        </button>
                        <button className="copy-enlace" onClick={() => openLinkInNewTab(ad.id)} title='Abrir en nueva pestaña'>
                          <img src={Abrir} alt="Abrir nueva pestaña" style={{ width: '25px', height: '25px' }} />
                        </button>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            )}
          </div>
        )
      )}
    </div>
  );
}

export default AdDetails;
