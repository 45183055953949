import { PIN_API_KEY, PIN_SEC_KEY } from '../config/config';
import axios from 'axios';
import FormData from 'form-data';
// ...

export const pinFileToIPFS = async (input) => {
  try {
    console.log("Inicio de la función pinFileToIPFS");
    console.log("Input recibido:", input);

    // Verificación adicional
    if (!input) {
      console.error("Error: Entrada proporcionada no es válida");
      throw new Error("Invalid input provided");
    }

    // Verificación adicional para objetos de archivo
    let fileName, fileType, blobFile;

    // Si se nos pasa un CID en lugar de un archivo
    if (typeof input === 'string') {
      console.log("Procesando input como CID");
      return input; // Retorna directamente el CID
    }
    // Si se nos pasa un objeto de imagen
    else if (input && input.image) {
      console.log("Procesando input como objeto de imagen");

      fileType = input.image.format === 'png' ? 'image/png' : 'image/jpeg';
      const blob = Buffer.from(input.image.data, 'base64');
      blobFile = new Blob([blob], { type: fileType });
      fileName = `uploaded_image.${input.image.format}`;
    } 
    // Si se nos pasa un archivo directamente
    else if (input instanceof File || (input.name && input.type)) {
      console.log("Procesando input como instancia de archivo");

      blobFile = input;
      fileName = input.name;
      fileType = input.type; 
    } else {
      console.error("Error: Entrada proporcionada no es válida");
      throw new Error("Invalid input provided");
    }

    // Subir el archivo a IPFS y obtener el CID
    const url = `https://api.pinata.cloud/pinning/pinFileToIPFS`;
    const data = new FormData();

    data.append('file', blobFile, fileName);

   

    return axios
      .post(url, data, {
        maxBodyLength: 'Infinity',
        headers: {
          'Content-Type': `multipart/form-data; boundary=${data._boundary}`,
          pinata_api_key: PIN_API_KEY,
          pinata_secret_api_key: PIN_SEC_KEY
        }
      })
      .then(function (response) {
        console.log("Respuesta exitosa de Pinata:", response);
        // Devuelve el CID del archivo subido
        return response.data.IpfsHash; 
      })
      .catch(function (error) {
        console.error("Error al interactuar con Pinata:", error);
        throw error;
      });
  } catch (error) {
    console.error("Error en la función pinFileToIPFS:", error);
    throw error;
  }
};

