import React, { useState, useEffect, useCallback } from 'react';
import Box from '../../assets/images/boxuniverse500.png';
import { toast } from 'react-toastify';
import { useWeb3React } from '@web3-react/core';
import { pinFileToIPFS } from '../../api/pinata';
import { HormigaTokenContract, RemitoHormigaNFTcontractAddress, setApproved, mint } from '../../web3';
import BigNumber from 'bignumber.js';
import '../../style/pages/nftMinting.scss';

const NFTMinting = () => {
  const [imageFile, setImageFile] = useState(null);
  const [valorDeclarado, setValorDeclarado] = useState('');
  const [recompensa, setRecompensa] = useState('');
  const [tiempoLimite, setTiempoLimite] = useState('');
  const [liberatingWallet, setLiberatingWallet] = useState('');
  const [origen, setOrigen] = useState('');
  const [destino, setDestino] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [logs, setLogs] = useState([]);
  const [useMyWalletAddress, setUseMyWalletAddress] = useState(false);
  const [isMintButtonDisabled, setIsMintButtonDisabled] = useState(false);
  const { active, account, chainId } = useWeb3React();
  const [previewImage, setPreviewImage] = useState(null);
  const [userAllowance, setUserAllowance] = useState('0');
  const TOKEN_DECIMALS = 0;
  const toTokenUnits = (amount) => new BigNumber(amount).multipliedBy(new BigNumber(10).pow(TOKEN_DECIMALS));
  const fromTokenUnits = (amount) => new BigNumber(amount).dividedBy(new BigNumber(10).pow(TOKEN_DECIMALS));
  const isValidEthereumAddress = (address) => /^(0x)[0-9A-Fa-f]{40}$/.test(address);
  const formatAmount = (amount) => parseFloat(fromTokenUnits(amount)).toLocaleString('en-US', { minimumFractionDigits: 0, maximumFractionDigits: 0 });

  const fetchAllowance = useCallback(async () => {
    try {
      const allowance = await HormigaTokenContract.methods.allowance(account, RemitoHormigaNFTcontractAddress).call();
      setUserAllowance(allowance);
    } catch (error) {
      console.error("Error al obtener la asignación de usuario:", error);
    }
  }, [account]);

  useEffect(() => {
    if (active) {
      fetchAllowance();
    }
  }, [active, fetchAllowance]);

  useEffect(() => {
    if (active) {
      const intervalId = setInterval(() => {
        fetchAllowance();
      }, 1000);

      return () => {
        clearInterval(intervalId);
      };
    }
  }, [active, fetchAllowance]);

  const handleFileChange = (event) => {
    try {
      const file = event.target.files[0];
      if (file) {
        const fileExtension = file.name.split('.').pop().toLowerCase();
        if (fileExtension === 'png' || fileExtension === 'jpg' || fileExtension === 'jpeg') {
          setImageFile(file);
          setPreviewImage(URL.createObjectURL(file));
        } else {
          toast.error("¡Tipo de archivo no compatible! Cargue un archivo PNG o JPG.");
        }
      }
    } catch (error) {
      console.error("Error al configurar el archivo de imagen:", error);
    }
  };

  useEffect(() => {
    if (logs.length) {
      setIsLoading(true);
      setTimeout(() => {
        setIsLoading(false);
      }, 700);
    }
  }, [logs]);

  useEffect(() => {
    if (useMyWalletAddress && account) {
      setLiberatingWallet(account);
    }
  }, [useMyWalletAddress, account]);

  const addLog = (message) => {
    setLogs([message]);
  };

  const handleApprove = async () => {
    try {
      const numValorDeclarado = toTokenUnits(valorDeclarado);
      const numRecompensa = toTokenUnits(recompensa);
      const totalAmount = numValorDeclarado.plus(numRecompensa);

      addLog("Iniciando aprobación de token...");
      setIsLoading(true);

      await setApproved(totalAmount.toString(), account);
      addLog("Tokens aprobados exitosamente.");
      toast.success("Tokens aprobados con éxito!");
      
    } catch (error) {
      addLog("Error durante el proceso de aprobación.");
      console.error("Error durante el proceso de aprobación:", error);
      toast.error("Error de aprobación del token!");
     
    } finally {
      setIsLoading(false);
    }
  };

  const handleMint = async () => {
    try {
      setIsMintButtonDisabled(true);

      if (!liberatingWallet || !isValidEthereumAddress(liberatingWallet)) {
        addLog("Dirección de Ethereum no válida.");
        toast.error("¡Ingrese una dirección Ethereum válida!");
        return;
      }

      if (!chainId || chainId !== 137) {
        addLog("conectese a la red de polygon ");
        toast.error("¡Cambie a la red correcta!");
        return;
      }

      if (!imageFile) {
        addLog("Archivo de imagen no proporcionado.");
        toast.error("¡Por favor ingrese el archivo de imagen!");
        return;
      }

      const numValorDeclarado = toTokenUnits(valorDeclarado);
      const numRecompensa = toTokenUnits(recompensa);
      const totalAmount = numValorDeclarado.plus(numRecompensa);

      const imageCID = await pinFileToIPFS(imageFile);

      if (!imageCID) {
        addLog("Error cargando imagen a IPFS.");
        toast.error("Error cargando imagen IPFS.");
        return;
      }

      const userDateTime = new Date(tiempoLimite);
      if (!userDateTime) {
        addLog("Fecha y hora no proporcionadas.");
        toast.error("Fecha y hora no proporcionadas.");
        return;
      }

      const userOffsetInMinutes = userDateTime.getTimezoneOffset();
      userDateTime.setMinutes(userDateTime.getMinutes() - userOffsetInMinutes);
      const utcDateTime = userDateTime.toISOString();

      const allowance = await HormigaTokenContract.methods
        .allowance(account, RemitoHormigaNFTcontractAddress)
        .call();
      const bigAllowance = new BigNumber(allowance);

      if (bigAllowance.isLessThan(totalAmount)) {
        addLog("Tokens no aprobados, iniciando aprobación...");
        setIsLoading(true);
        await setApproved(totalAmount.toString(), account);
        addLog("Tokens aprobados exitosamente.");
      }

      addLog("Iniciando la carga del archivo a IPFS.");
      addLog("Acuñando...");

      setIsLoading(true);
      await mint(
        fromTokenUnits(numValorDeclarado).toString(),
        fromTokenUnits(numRecompensa).toString(),
        utcDateTime,
        liberatingWallet,
        imageCID,
        account,
        origen,
        destino
      );

      addLog("NFT acuñado con éxito!");
      toast.success("NFT acuñado con éxito!");
    } catch (error) {
      addLog("Error durante el proceso de acuñado.");
      console.error("Error durante el proceso de acuñado.", error);
      toast.error("La creación del NFT ha fallado!");
    } finally {
      setIsLoading(false);
      setIsMintButtonDisabled(false);
    }
  };

  function getCurrentDateTimeInUTC() {
    const now = new Date();
    const formattedDate = `${now.getUTCDate()} ${getMonthName(now.getUTCMonth())} ${now.getUTCFullYear()} ${now.toISOString().slice(11, 16)}`; // Formato: DD Mes AAAA HH:mm
    return formattedDate;
  }

  function getMonthName(month) {
    const months = [
      'January', 'February', 'March', 'April', 'May', 'June',
      'July', 'August', 'September', 'October', 'November', 'December'
    ];
    return months[month];
  }

  const calculateCommission = (valorDeclarado, recompensa) => {
    const valor = parseFloat(valorDeclarado) || 0;
    const recompensaFloat = parseFloat(recompensa) || 0;
    const totalAmount = valor + recompensaFloat;
    const commission = (totalAmount * 0.01) / 100; // 0.01%
    return parseFloat(commission.toFixed(8)); // Convertir a número y ajustar la precisión
  };

  const commission = calculateCommission(valorDeclarado, recompensa);

  return (
    <div className='homepage'>
      <div className='left-panel'>
        <img
          src={Box}
          alt="Hormiga logo"
          className={`logo ${isLoading ? 'spinning' : ''}`}
          style={{ width: '250px' }}
        />

        <div className='logs' style={{ fontSize: '1.5em' }}>
          {logs.map((log, index) => (
            <h3 key={index}>{log}</h3>
          ))}
        </div>

        {!previewImage && <p>Por favor carga una imagen</p>}

        <input
          type="file"
          className='input-box'
          accept=".png, .jpg, .jpeg"
          onChange={handleFileChange}
        />

        {previewImage &&
          <div style={{ textAlign: 'center', margin: '20px 0' }}>
            <img src={previewImage} alt="Preview" style={{ width: '80%', maxHeight: '200px', objectFit: 'contain' }} />
          </div>
        }

        <input
          type="text"
          className='input-box'
          placeholder='Valor declarado en HTC'
          value={valorDeclarado}
          maxLength="13"
          onChange={(e) => {
            if (e.target.value.length <= 13) {
              setValorDeclarado(e.target.value);
            }
          }}
        />

        <input
          type="text"
          className='input-box'
          placeholder='Recompensa en HTC'
          value={recompensa}
          maxLength="13"
          onChange={(e) => {
            if (e.target.value.length <= 13) {
              setRecompensa(e.target.value);
            }
          }}
        />

        <div>
          <span>Crear un remito tiene una comisión del 0,01% lo que en este caso es: <span style={{ color: 'green' }}>{commission} HTC.</span> </span>
        </div>

        <p className='input-label'>Fecha y hora en UTC: <span style={{ color: 'celeste' }}>{getCurrentDateTimeInUTC()}</span></p>

        <label className='input-label'>
          Ingrese la fecha máxima de entrega, en hora UTC
        </label>
        <input
          type="datetime-local"
          className='input-box'
          value={tiempoLimite}
          min={new Date().toISOString().slice(0, 16)}
          onChange={(e) => setTiempoLimite(e.target.value)}
        />
        <input
          type="text"
          className='input-box'
          maxLength="150"
          placeholder='Origen'
          value={origen}
          onChange={(e) => setOrigen(e.target.value)}
        />

        <input
          type="text"
          className='input-box'
          maxLength="150"
          placeholder='Destino'
          value={destino}
          onChange={(e) => setDestino(e.target.value)}
        />

        <input
          type="text"
          className='input-box'
          placeholder='Cartera liberadora 0x...'
          value={liberatingWallet}
          onChange={(e) => setLiberatingWallet(e.target.value)}
          disabled={useMyWalletAddress}
        />

        <div className="checkbox-container">
          <h3>Usar mi cartera</h3>
          <input
            type="checkbox"
            checked={useMyWalletAddress}
            onChange={(e) => setUseMyWalletAddress(e.target.checked)}
          />
        </div>
        <div style={{ color: '#c9c9c9', marginTop: '10px' }}>
          Marque la casilla si desea designar su dirección actual como la responsable de liberar los fondos. Esta dirección estará autorizada para desbloquear los fondos congelados y transferirlos al titular del NFT. Además, en caso de solicitar un reembolso, los fondos se transferirán a esta dirección.
        </div>

        <div style={{ color: '#32CD32', marginTop: '10px' }}>
          Tokens HTC aprobados para usar: {formatAmount(userAllowance)}
        </div>

        <button className='component-button' onClick={handleApprove}>
          Aprobar
        </button>

        <br></br>
        <div>

        </div>
        <button className='component-button' onClick={handleMint} disabled={isMintButtonDisabled}>
          Crear NFT
        </button>

        <p style={{ color: 'white', marginTop: '10px' }}>
          Necesitas tener el token <a href="https://coinmarketcap.com/es/currencies/polygon/" target="_blank" rel="noopener noreferrer" style={{ color: 'violet', textDecoration: 'none' }}>Matic</a> para esta operación y debes estar conectado a la red de  <a href="https://polygon.technology/" target="_blank" rel="noopener noreferrer" style={{ color: 'violet', textDecoration: 'none' }}>Polygon</a>.
        </p>
      </div>
    </div>
  );
};

export default NFTMinting;
