import axios from 'axios';
import { auth } from './../../firebase.js';

export const handleDeleteComment = async (pubId, commentId, fetchComments, setNotificationMessage, setIsLoading) => {
  try {
    setIsLoading(true);
    const token = await auth.currentUser.getIdToken();
    await axios.delete(`https://us-central1-hormiga-631f9.cloudfunctions.net/deleteComentario`, {
      headers: {
        'Authorization': `Bearer ${token}`
      },
      data: { anuncioId: pubId, comentarioId: commentId },
    });
    setNotificationMessage('Comentario borrado con éxito');
    await fetchComments(pubId);
  } catch (error) {
    console.error('Error borrando comentario:', error);
    setNotificationMessage('Hubo un error al borrar el comentario.');
  } finally {
    setIsLoading(false);
  }
};
