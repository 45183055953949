import { createStore } from "redux";

const initialState = {
  address: [],
  connected: false,
  serverWeb3Provider: null,
  modalShow: false

};
const reducer = (state = initialState, action) => {
  switch (action.type) {
    case "ADD_ADDRESS": {
      return {
        address: [...state.address, ...action.payload],
      };
    }

    case "CONNECT_DEFI": {
      
      return {
        address: action.payload.address,
        serverWeb3Provider: action.payload.browserProvider,
        connected: action.payload.connected,
      };
    }
    case "REMOVE_ADDRESS": {
      return {
        ...state,
        address: state.address.filter((ad) => ad === action.payload),
      };
    }

    case "MODAL_SHOW": {
      return {
        ...state,
        modalShow: action.payload
      };
    }
    case "DISCONNECT": {
      return {
        ...state,
        connected: false        
      };
    }

    default:
      return state;
  }
};

const store = createStore(reducer);

export default store;
