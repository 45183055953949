import axios from 'axios';
import { auth } from './../../firebase.js';

export const handleSendMessage = async (userId, messageContent, setNotificationMessage, setIsLoading, setIsSubmitting) => {
    if (!messageContent.trim()) {
        setNotificationMessage('El mensaje no puede estar vacío');
        setIsSubmitting(false);
        return;
    }

    setIsLoading(true); // Mostrar el spinner de carga
    try {
        const token = await auth.currentUser.getIdToken();

        const recaptchaToken = await window.grecaptcha.execute('6LfMfAsqAAAAAJlMEX16BxL8_PU0CIC-vMlyTsvZ', { action: 'submit' });

        const data = { recipientUserId: userId, messageText: messageContent, recaptchaToken };

        const response = await axios.post(
            'https://us-central1-hormiga-631f9.cloudfunctions.net/sendMessage',
            data,
            {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            }
        );

        if (response.status === 200) {
            setNotificationMessage('Mensaje enviado');
        } else {
            console.error('Error al enviar el mensaje:', response);
            setNotificationMessage('Error mensaje no enviado');
        }
    } catch (error) {
        setNotificationMessage('Error mensaje no enviado');
    } finally {
        setIsSubmitting(false);
        setIsLoading(false); // Ocultar el spinner de carga
    }
};
