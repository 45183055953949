// hooks/fetchUserRating.js
import axios from 'axios';
import { auth } from './../../firebase.js';

export const fetchUserRating = async (userId, setUserRating, setNotificationMessage) => {
    try {
        const token = await auth.currentUser.getIdToken(true);
        const recaptchaToken = await window.grecaptcha.execute('6LfMfAsqAAAAAJlMEX16BxL8_PU0CIC-vMlyTsvZ', { action: 'submit' });
        const response = await axios.get('https://us-central1-hormiga-631f9.cloudfunctions.net/getAnuncio', {
            headers: {
                Authorization: `Bearer ${token}`
            },
            params: { userId, recaptchaToken }
        });

        const data = response.data.find(pub => pub.userId === userId);
        if (data) {
            setUserRating({
                averageRating: data.averageRating || 'N/A',
                ratingCount: data.ratingCount || 'N/A'
            });
        }
    } catch (error) {
        setNotificationMessage(`Error al obtener las calificaciones: ${error.response?.data?.error || 'Error desconocido'}`);
    }
};
