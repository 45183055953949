export const PIN_API_KEY = "b29c225c25d9e0096151";
export const PIN_SEC_KEY = "56e2236dbafce86cca1d0c2473dedccc0ce4e8a8831893fe8af1ac6aac215edd";

export const pinata = "https://cloudflare-ipfs.com/";

export const configVars = {
  mode: "regular",
  

  rpcNetwork_polygon: {
    rpcUrl: "https://polygon-mainnet.infura.io/v3/dc4343554c5147cab49e788c355f074b",
    chainId: 137,
    chainIdHex: "0x89", 
    chainName: "Polygon",
    chainType: "mainnet",
    nativeCurrency: {
      name: "MATIC",
      symbol: "MATIC",
      decimals: 18,
    },
    blockExplorerUrl: "https://polygonscan.com/",
}

 
}











// export const PIN_API_KEY = "b29c225c25d9e0096151";
// export const PIN_SEC_KEY = "56e2236dbafce86cca1d0c2473dedccc0ce4e8a8831893fe8af1ac6aac215edd";

// export const pinata = "https://cloudflare-ipfs.com/";

// export const configVars = {
//   mode: "regular",
  

//   rpcNetwork_sepolia: {
//     rpcUrl: "https://sepolia.infura.io/v3/dc4343554c5147cab49e788c355f074b",
//     chainId: 11155111,
//     chainIdHex: "HEX_ID_CHAIN_SEPOLIA",
//     chainName: "Sepolia",
//     chainType: "testnet",
//     nativeCurrency: {
//       name: "ETH",
//       symbol: "ETH",
//       decimals: 18,
//     },
//     blockExplorerUrl: "https://sepolia.etherscan.io/",
//   },

//   rpcNetwork_ethereum: {
//     rpcUrl: "https://mainnet.infura.io/v3/dc4343554c5147cab49e788c355f074b",
//     chainId: 1,
//     chainIdHex: "0x1",
//     chainName: "Ethereum Mainnet",
//     chainType: "mainnet",
//     nativeCurrency: {
//       name: "ETH",
//       symbol: "ETH",
//       decimals: 18,
//     },
//     blockExplorerUrl: "https://etherscan.io/",
//   }
// }