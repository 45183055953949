import { initializeApp } from "firebase/app";
import { getAuth, GoogleAuthProvider, OAuthProvider, signInWithRedirect } from "firebase/auth";
import { getDatabase } from "firebase/database";
import { getFirestore } from "firebase/firestore"; // Importa Firestore

// Configuración de Firebase
const firebaseConfig = {
  apiKey: "AIzaSyBUnHzHGzduOXuwD_286ta9Eqr9otl2wYg",
  authDomain: "hormiga-631f9.firebaseapp.com",
  databaseURL: "https://hormiga-631f9-default-rtdb.firebaseio.com",
  projectId: "hormiga-631f9",
  storageBucket: "hormiga-631f9.appspot.com",
  messagingSenderId: "259587586685",
  appId: "1:259587586685:web:43b0574658ed799b1d0f14",
  measurementId: "G-T5GRDHS5R1"
};

// Inicializa Firebase
const app = initializeApp(firebaseConfig);

// Inicializa Firebase Auth y configuración de los proveedores de autenticación
const auth = getAuth(app);
const googleProvider = new GoogleAuthProvider();
const appleProvider = new OAuthProvider('apple.com');

// Inicializa la base de datos en tiempo real de Firebase
const db = getDatabase(app);

// Inicializa Firestore
const firestore = getFirestore(app); // Inicializa Firestore

// Función para iniciar sesión con Google usando signInWithRedirect
export const signInWithGoogle = () => {
  signInWithRedirect(auth, googleProvider)
    .then(() => {
      // Redirigir a la página de inicio o manejar la redirección según tu aplicación
    })
    .catch((error) => {
      console.error("Error al iniciar sesión con Google:", error);
    });
};

export { auth, googleProvider, appleProvider, db, firestore }; // Exporta Firestore
export default app;


