import React from 'react';
import './../../style/pages/instructions.scss';

const Instructions = () => {
    return (
        <div className="instructions">
            <h1>Instrucciones de Uso</h1>
            <h2>¿Cómo Convertirse en una Hormiga?</h2>

            <p>Mira los videos!</p>
            <div className="youtube-video">
            <iframe width="656" height="369" src="https://www.youtube.com/embed/-GNP7qdrz0Q" title="HormgaDApp actualizaciones" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
            </div>

            <ol>
                <li>Navegue a la sección "Publicar" para crear su anuncio. Aquí, deberá proporcionar detalles sobre su viaje, incluyendo el origen, destino y cualquier información relevante, como el valor máximo declarado y otros detalles importantes que pueda incluir en el campo de descripción.Si desea ser transportista seleccione la opción: "Llevar" pero si desea enviar algo seleccione la opción: "Enviar".</li>
                <li>Una vez que haya publicado su anuncio, simplemente espere a que alguien muestre interés en su oferta y se comunique con usted utilizando la información de contacto proporcionada. Mientras tanto, también puede buscar anuncios de otras personas que deseen enviar artículos de un lugar a otro.</li>
                <li>Para llevar a cabo la transacción de forma segura, y no depender de la confianza humana, puede crear un "HormigaNFT" desde la sección "Mint". Este es un NFT fondeado con el valor declarado + la recompensa usando los tokens HTC segun su cotización, para esto necesitará tener tokens HTC en su cuenta y tokens MATIC para pagar las transacciones en Metamask. El remitente que desee enviar el artículo es quien crea este NFT que funciona como un contrato. Es esencial que el transportista comprenda la importancia de conocer el artículo que está transportando, asegurándose de que su valor coincida con lo que el remito dice y que esté en las condiciones acordadas.</li>
                <li>Una vez que haya coordinado con el remitente y recogido el paquete, el emisor deberá transferirle el NFT a la dirección de la billetera cripto del transportista. Luego, podrá ver el NFT en su cuenta a través de la aplicación, en la sección "NFTs".</li>
                <li>Al momento de recibir el NFT junto con la mercancía,el transportista debe realizar el pago al remitente por el valor declarado de este contrato.Una vez que el remitente sepa que el transportista entregó la mercaderia o esta frente al destinatario, este podrá liberar los fondos desde la pagina  "NFTs" opcion "Remitos pendientes de firma" y el transportista recibirá el pago en <a href="https://polygonscan.com/address/0x82ef5dA554D067E227B18182D49F200e0017c56F" target="_blank" rel="noopener noreferrer" style={{ color: 'gold', textDecoration: 'underline' }}>HTC</a>, la moneda de la aplicación, recibiendo el total del valor del contrato: el valor declarado + la recompensa. A partir de ese momento, puede decidir cómo administrar su pago, ya sea convirtiéndolo en otras criptomonedas o conservando los tokens para uso futuro.</li>
            </ol>

            <p>Finalmente, es crucial que sepa que tener un buen entendimiento de la herramienta es fundamental si desea explotar el 100% de su potencial,y evitar problemas que puedan afectarle adversamente. Tenga en cuenta que esta es una aplicación descentralizada sin soporte ni asistencia mas que el de su propia comunidad, y nadie será responsable de su mal uso.Y si bien la herramienta es bastante intuitiva, el usuario puede llegarse a confundir si no ha estudiado el funcionamiento y la logica del negocio, como también podría ser estafado.</p>

            <h1>Requisitos Esenciales</h1>
            <h4>*Tener la extensión Metamask instalada en su navegador (se recomienda usar el navegador Brave).</h4>
            <h4>*Tener <a href="https://polygonscan.com/address/0xda4FDbB2C31d8805a08736A76DF2afcF43Be7Ee1" target="_blank" rel="noopener noreferrer" style={{ color: '#F0B90B' }}>tokens HTC</a> si desea crear un HormigaNFT.Estos se pueden obtener en el <a style={{ color: 'green' }} href="./swap">SWAP</a> </h4>
            <h4>*Tener tokens Matic para poder usar la red de Polygon.  </h4>
            <h4>*Si planea usar la aplicación en su teléfono, debe usarla dentro del navegador Metamask, por lo que deberá tener Metamask en su dispositivo.</h4>

            <h1>Smart contracts </h1>
            <h4>La DApp funciona con tres smart cotracts en la blockchain de polygon, puede encontrarlos en el explorador de bloques</h4>
            <h4><a href="https://polygonscan.com/address/0xda4FDbB2C31d8805a08736A76DF2afcF43Be7Ee1" target="_blank" rel="noopener noreferrer" style={{ color: 'violet', textDecoration: 'underline' }}>Contrato token erc20 HTC:</a></h4>

            <h4><a href="https://polygonscan.com/address/0x73822843288FD1A1AB207f63c4f588BEB07028f0" target="_blank" rel="noopener noreferrer" style={{ color: 'violet', textDecoration: 'underline' }}>Contrato token erc721 Remito-NFT :</a></h4>

            <h4><a href="https://polygonscan.com/address/0xd5EcAE35B0E385459eddF1b07d4F1A1927963789" target="_blank" rel="noopener noreferrer" style={{ color: 'violet', textDecoration: 'underline' }}>Contrato token SWAP:</a></h4>

            <h4><a href="./proyecto-hormiga" target="_blank" rel="noopener noreferrer" style={{ color: '#F0B90B' }}>Mas sobre la aplicación...</a></h4>

        </div>
    );
}

export default Instructions;
