import React, { useState } from 'react';
import axios from 'axios';
import { getAuth } from "firebase/auth";
import { GoogleReCaptchaProvider, useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import './../../../src/style/pages/createPublication.scss';

function CreatePublication() {
  const initialFormState = {
    city: '',
    province: '',
    country: '',
    phone: '',
    departureDate: '',
    maxLoadValue: '',
    destinationCity: '',
    destinationProvince: '',
    destinationCountry: '',
    arrivalDate: '',
    descriptionItem: '',
    option: '',
  };

  const [newPublication, setNewPublication] = useState(initialFormState);
  const [isLoading, setIsLoading] = useState(false);
  const [alertMessages, setAlertMessages] = useState([]);
  const { executeRecaptcha } = useGoogleReCaptcha();

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setNewPublication((prevState) => ({ ...prevState, [name]: value }));
  };

  const auth = getAuth();

  const isFormValid = () => {
    return newPublication.arrivalDate >= newPublication.departureDate;
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    const newAlertMessages = [];

    if (!isFormValid()) {
      newAlertMessages.push('Revisa las fechas');
    }

    if (!auth.currentUser) {
      newAlertMessages.push('Debes iniciar sesión para crear un anuncio.');
    }

    if (newAlertMessages.length > 0) {
      setAlertMessages(newAlertMessages);
      return;
    }

    if (!executeRecaptcha) {
      setAlertMessages(['Error inicializando reCAPTCHA']);
      return;
    }

    setIsLoading(true);
    try {
      const recaptchaToken = await executeRecaptcha('createPublication');
      const userToken = await auth.currentUser.getIdToken(true);

      await axios.post(
        'https://us-central1-hormiga-631f9.cloudfunctions.net/createAnuncio',
        {
          ...newPublication,
          recaptchaToken,
        },
        {
          headers: {
            Authorization: `Bearer ${userToken}`,
          },
        }
      );
      setAlertMessages(['Anuncio publicado!']);
      setNewPublication(initialFormState);
    } catch (error) {
      console.error('Error creando anuncio:', error);
      setAlertMessages(['Estas haciendo algo mal']);
    } finally {
      setIsLoading(false);
    }
  };

  const handleOptionChange = (event) => {
    const { value } = event.target;
    setNewPublication((prevState) => ({ ...prevState, option: value }));
  };

  return (
    <div className="publication-container">
      {isLoading && (
        <div className="loading-animation">
          <div className="spinner"></div>
        </div>
      )}

      {alertMessages.map((message, index) => (
        <div key={index} className="alert" onClick={() => {
          const newMessages = alertMessages.filter((_, i) => i !== index);
          setAlertMessages(newMessages);
        }}>
          {message}
        </div>
      ))}

      <form onSubmit={handleSubmit}>
        <div className="form-columns">
          <div className="left-panel">
            <h2>Origen</h2>

            <div>
              <label htmlFor="city" className="input-label">Ciudad de origen:</label>
              <input
                type="text"
                id="city"
                name="city"
                value={newPublication.city}
                onChange={handleInputChange}
                maxLength="20"
                className="input-box"
              />
            </div>

            <div>
              <label htmlFor="province" className="input-label">Provincia de origen:</label>
              <input
                type="text"
                id="province"
                name="province"
                value={newPublication.province}
                onChange={handleInputChange}
                maxLength="20"
                className="input-box"
              />
            </div>
        
            <div>
              <label htmlFor="country" className="input-label"> País de origen:</label>
              <input
                type="text"
                id="country"
                name="country"
                value={newPublication.country}
                onChange={handleInputChange}
                maxLength="20"
                className="input-box"
              />
            </div>

            <div>
              <label htmlFor="phone" className="input-label">Teléfono:</label>
              <input
                type="tel"
                id="phone"
                name="phone"
                value={newPublication.phone}
                onChange={handleInputChange}
                maxLength="20"
                className="input-box"
              />
            </div>

            <div>
              <label htmlFor="departureDate" className="input-label">Fecha de salida:</label>
              <input
                type="date"
                id="departureDate"
                name="departureDate"
                value={newPublication.departureDate}
                onChange={handleInputChange}
                className="input-box"
              />
            </div>

            <div>
              <label htmlFor="maxLoadValue" className="input-label">Valor maximo de carga:</label>
              <input
                type="number"
                id="maxLoadValue"
                name="maxLoadValue"
                placeholder='Valor declarado del bien a transportar.'
                value={newPublication.maxLoadValue}
                onChange={handleInputChange}
                maxLength="20"
                className="input-box"
              />
            </div>

            <div>
              <label htmlFor="reward" className="input-label">Recompensa:</label>
              <input
                type="number"
                id="reward"
                name="reward"
                placeholder='Cuanto quieres u ofreces de recompensa.'
                value={newPublication.reward}
                onChange={handleInputChange}
                maxLength="20"
                className="input-box"
              />
            </div>
          </div>

          <div className="right-panel">
            <h2>Destino</h2>

            <div>
              <label htmlFor="destinationCity" className="input-label">Ciudad de destino:</label>
              <input
                type="text"
                id="destinationCity"
                name="destinationCity"
                value={newPublication.destinationCity}
                onChange={handleInputChange}
                maxLength="20"
                className="input-box"
              />
            </div>

            <div>
              <label htmlFor="destinationProvince" className="input-label">Provincia de destino:</label>
              <input
                type="text"
                id="destinationProvince"
                name="destinationProvince"
                value={newPublication.destinationProvince}
                onChange={handleInputChange}
                maxLength="20"
                className="input-box"
              />
            </div>

            <div>
              <label htmlFor="destinationCountry" className="input-label">País de destino:</label>
              <input
                type="text"
                id="destinationCountry"
                name="destinationCountry"
                value={newPublication.destinationCountry}
                onChange={handleInputChange}
                maxLength="20"
                className="input-box"
              />
            </div>

            <div>
              <label htmlFor="arrivalDate" className="input-label">Fecha de llegada:</label>
              <input
                type="date"
                id="arrivalDate"
                name="arrivalDate"
                value={newPublication.arrivalDate}
                onChange={handleInputChange}
                className="input-box"
              />
            </div>

            <div>
              <label htmlFor="descriptionItem" className="input-label">Descripción:</label>
              <textarea
                id="descriptionItem"
                name="descriptionItem"
                value={newPublication.descriptionItem}
                onChange={handleInputChange}
                maxLength="100"
                placeholder="Introduce información relevante sobre lo que necesitas, expresate libremente...Intenta ser claro y prolijo."
                className="input-box"
              ></textarea>
            </div>

            <label className="input-label">Acción:</label>
            <div className="radio-group">
              <label className="radio-label">
                <input
                  type="radio"
                  name="option"
                  value="Envía"
                  checked={newPublication.option === 'Envía'}
                  onChange={handleOptionChange}
                />
                Enviar
              </label>
              <label className="radio-label">
                <input
                  type="radio"
                  name="option"
                  value="Transporta"
                  checked={newPublication.option === 'Transporta'}
                  onChange={handleOptionChange}
                />
                Transportar
              </label>
            </div>
            <button type="submit" className="component-button2" disabled={isLoading}>
              {isLoading ? "Enviando..." : "Crear publicación"}
            </button>
          </div>
        </div>
      </form>
    </div>
  );
}

export default function App() {
  return (
    <GoogleReCaptchaProvider reCaptchaKey="6LfMfAsqAAAAAJlMEX16BxL8_PU0CIC-vMlyTsvZ">
      <CreatePublication />
    </GoogleReCaptchaProvider>
  );
}
