import React from 'react';
import './../../style/pages/proyecto-hormiga.scss';
import logoHTC from './../../assets/images/ant-circle.png';
import cuelodebotella from './../../assets/images/cuellodebotella.png';
import escalabilidad from './../../assets/images/protocolexplication.png'
import mariano from './../../assets/images/mariano.jpg';


const ProyectoHormiga = () => {
    return (
        <div className="proyecto-hormiga">
            <h1>Proyecto Hormiga</h1>
            <h2>Mas sobre la aplicación...</h2>

            <p>¿Qué es el proyecto Hormiga?</p>

            <p>
                Un universo de posibilidades dentro de un protocolo descentralizado. Hormiga pretende ser la solución a los más grandes problemas de la humanidad detrás de un proceso de búsqueda de libre mercado en una interface p2p.
            </p>

            <p>
                ¿Qué solución brindará?
                Creará una alternativa de comercio con precios de libre mercado, donde se optimizará la gestión de transporte, causando un proceso de búsqueda de la mejor solución para la logística de infinidades de productos que se deseen trasladar incluso a los lugares mas recónditos del planeta.
                La gran mayoría de usuarios se estima  que serán personas que de por sí iban a viajar hacia el lugar, como también habrá aquellos que empiecen a hacerlo, por lo que resulta una oportunidad al aprovechar el espacio de carga muchas veces desperdiciado, mientras se gana dinero por ello. Esto optimiza la gestión de transporte ahorrando energías, bajando los costos, acelerando el proceso productivo, y por si fuera poco, evitando los cuellos de botellas que suelen ser lugares de focos de infección.
            </p>


            <div className="imagen-container">
                <img src={cuelodebotella} alt="cuello de botella" className="imagen" />
            </div>

            <p>
                Cuantificar y monetizar lo desperdiciado
                Durante toda la existencia de la humanidad, jamás tuvimos la tecnología para desarrollar este proceso evolutivo, pero con el protocolo que estamos desarrollando, hemos de crear el petróleo digital, ya que al utilizar tokens erc20 con suministro limitado, y que en su uso son congelados durante el proceso mediante la programación de Smart contracts, nos permite tener un activo de precio equivalente al costo de energía de transporte humana, este mismo será congelado en distintas piscinas tras la ejecución del pertinente Smart contract, dando de esta manera una estabilidad a un token que cuantifica la energía hasta el día de hoy desperdiciada.
            </p>

            <div className="imagen-container">
                <img src={logoHTC} alt="logo HTC" className="imagen" />
            </div>

            <p>
                ¿Dónde está la clave?
                Utilizaremos, nuestra propia moneda de intercambio, un token erc20 de total cantidad limitada en 7.000.000.000.000.- (7 millones, con 6 decimales). De esta manera el token "Hormiga" (HTC) es ajeno a los problemas de las distintas monedas mundiales, y su valor es puesto por la oferta y demanda de los mismos, y por su uso y aplicación, este será el cuantificador de la energía de transporte, creando lo que entendemos sería una especie de petróleo verde digital, el HTC (Hormiga Token Coin). Por el momento aún no se están comercializando, debido a que no llegamos aún a esa fase del proyecto.
            </p>

            <p>
                ¡Pero en ello trabajamos!
            </p>

            <p>
                ¿Cómo funcionará?
                Cada usuario que opte por usar "Hormiga" para transportar un bien, deberá tener en su cuenta el saldo equivalente para usar de colateral según el valor declarado del bien a transportar, de esta manera si el paquete no llegase a tiempo, se extraviara, o se dañara en el camino, el receptor podría ejecutar el colateral para recuperar el dinero del bien que no llegó a recibir. Dejando al usuario la libertad de darle el valor que desee a los bienes, aunque no se trate del valor real de mercado.
                Por ejemplo: Si el emisor desea enviar un lingote de oro cuyo valor es de u$d 10,000.00 y desea declarar el valor u$d 10.00. La cláusula de seguro que liquidaría el colateral en caso de pérdida, sería por 10 u$d. Mientras que por otro lado, si un usuario enviara un par de zapatillas con un valor de u$d 10 dólares, pero desea declarar un valor de u$d 100,000. La cláusula liquidaría tal monto. Siempre recordando que la moneda del proceso es la "HTC".
            </p>

            <p>
                Este protocolo garantiza la seguridad porque incentiva a la responsabilidad y a la honestidad, ya que se transportarían bienes ajenos como si fueran propios, sabiendo que como transportadores cobraríamos la tarifa establecida o aceptada por nosotros mismos, la cual apela a la responsabilidad y a la elección del transportista el asumir la misma al momento de decidir participar de la operación.
            </p>
        

<p>
                Por otro lado, para evitar ser estafado por el emisor, todo aquel que envíe un paquete, deberá contar con un colateral en su cuenta que será descongelado ni bien la operación se concluya, esto último se realiza para evitar estafas de parte de emisores que podrían enviar bienes sin valor, con el fin de estafar al transportista.
            </p>

            <p>
                ¿Cómo funcionará?
                Ejemplo de un caso de uso:
            </p>

            <ol>
                <li>
                    <strong>Paso 1: Creación del Remito</strong>
                    <p>Actor: Emisor</p>
                    <p>El emisor quiere enviar un bien al receptor y para ello crea un remito, especificando un valor declarado de por ejemplo 30,000 HTC para el objeto y una recompensa de 5,000 HTC para el transportista que realizará la entrega.</p>
                    <p>El emisor crea el RemitoNFT, definiendo los parámetros pertinentes, incluyendo la dirección de la address liberadora, que será la que tenga el poder de liberar los fondos hacia el futuro tenedor del NFT, y a la vez será la que reciba el reembolso si eso debe ocurrir.</p>
                    <p>Al mintear el emisor transfiere los valores ingresados al contrato, en donde se congelan en las piscinas del mismo llamadas FER y FEV.</p>
                    <p>FER: Fondos de emisor:recompensa </p>
                    <p>FEV: Fondos de emisor:valor declarado.</p>
                    <p>(Estos valores se pueden visualizar colocando el cursor del mouse sobre el titulo de la aplicacion "Hormiga" y esta informacion puede ser util para la obtencion de metadata relacionada a la herramienta).</p>
                    <p>En fin, se crea un NFT representando el remito, y el emisor se convierte en el primer propietario del activo digital.</p>
      
                </li>
                <li>
                    <strong>Paso 2: Transporte del Bien</strong>
                    <p>Actor: Transportista</p>
                    <p>El emisor entrega físicamente el bien al transportista y le transfiere el NFT del remito, lo que le da el derecho de recibir la recompensa una vez completada la entrega.Al mismo tiempo el transportista le debe pagar al emisor el valor del producto que desea llevar, entendiendo que se trata de un valor real y no hay excesos ni arbitrariedades.</p>
                </li>
                <li>
                    <strong>Paso 3: Entrega del Bien y Recompensa</strong>
                    <p>Cuando el bien llega a su destino, el receptor (que puede ser la address liberadora) o el emisor tras ser avisado por el receptor que el bien ha llegado, llama a la función liberar fondos, desde la sección "NFTs" en la applicacion.</p>
                    <p>Si todo está en orden, el contrato transfiere los tokens previamente congelados hacia el actual poseedor del NFT (el transportista), marcando el remito como entregado.</p>
                </li>
                <li>
                    <strong>Caso Alternativo: No entrega y Reembolso</strong>
                    <p>Actor: Receptor</p>
                    <p>Si el bien no se entrega antes de la fecha límite, la address especificada como la address liberadora puede llamar a "Rembolsar" para recibir el valor declarado del bien y la recompensa, que se transfieren desde FEV y FER a la dirección de la address liberadora especificada al momento de crearse el NFT.</p>
                </li>
            </ol>

            <p>
                Conclusión
                En este sistema, el remito representado por un NFT actúa como un contrato vinculante que asegura que el transportista será recompensado una vez complete la entrega a satisfacción, siempre y cuando esto ocurra antes del tiempo límite definido. A su vez, garantiza que en caso de no cumplirse la entrega, el receptor puede recuperar el valor declarado del bien y la recompensa.
            </p>

            <p>
                Escalabilidad de los envíos.
                Al tratarse de un protocolo híbrido de libre mercado, la decisión humana del individuo le brindará a las partes la posibilidad de negociar un remito de envío con el fin de llegar a el mejor precio, establecido por la oferta y demanda.
            </p>


            <div className="imagen-container" style={{ backgroundColor: 'white', padding: '10px', maxWidth: '100%', overflow: 'hidden', marginBottom: '20px', display: 'flex', justifyContent: 'center' }}>
                <img src={escalabilidad} alt="escalabilidad" className="imagen" style={{ maxWidth: '100%', height: 'auto' }} />
            </div>

            <p>
                La hoja de ruta nos lleva más lejos de lo que parece.
            </p>
            <ul>
                <li>Desarrollo del protocolo.(Listo).</li>
                <li>Creación el <a href="https://polygonscan.com/address/0xda4FDbB2C31d8805a08736A76DF2afcF43Be7Ee1" target="_blank" rel="noopener noreferrer" style={{ color: '#F0B90B',textDecoration: 'underline' }}>token HTC</a>.(Listo).</li>
                <li>Creación el smart contract <a href="https://polygonscan.com/address/0x73822843288FD1A1AB207f63c4f588BEB07028f0" target="_blank" rel="noopener noreferrer" style={{ color: 'violet', textDecoration: 'underline' }}> Erc721 Remito-NFT :</a>(Listo).</li>
                <li>Creación del filtro de busqueda de viajeros y remitentes (Listo).</li>
                <li>Creación del <a style={{ color: 'green' }} href="./swap">SWAP</a> (Listo) Aqui hemos puesto el 100% del supply maximo de 7 millones con 6 decimales.(Hormiga Token Coin).</li>
            </ul>

            <p>
                La Dapp cobra un 0.01% del total fondeado en cada remito NFT creado.
            </p>

            <p>
                Este protocolo nos lleva muy lejos, brindando la oportunidad de crear remitos que lleven paquetes a los lugares más recónditos del planeta. Si tengo espacio de sobra para llevar algo al Everest, y la recompensa es grata. ¿Por qué no?
            </p>

            <p>
                Mas sobre la los desarrolladores
            </p>



            <div className="imagen-container">
                <img src={mariano} alt="Mariano Privitera" className="imagen" />
            </div>
            <p>
                Hola! mi nombre es Mariano Privitera, nací en Argentina, en 1988 y desde que apareció la web, mi segunda casa ha sido la internet, soy un autodidacta multitemático que nunca para de aprender, construyendo escalón por escalón, y si bien la historia de mi vida es larga, hoy estoy aquí para trabajar en este fascinante proyecto que me ha quitado el sueño.

                Si bien no me considero un pirata del Silicon Valley, creo que mi proyecto y mi idea tiene tantos buenos fundamentos que merece ser expuesta, compartida e incluso hasta desarrollada de manera urgente. Y para ello es que vengo aprendiendo distintas tecnologías de programación y haciendo todo tipo de contactos y amistades posibles, apostando a que grandiosa comunidad que nos une en internet, puede lograr cosas maravillosas aportando bienestar a nuestras vidas.

                Actualmente soy el propietario de una fábrica de balones deportivos situada en Argentina, así que mientras tanto pueden googlear Priball y comprarme una pelota de fútbol. (Pero no ahora!! Ahora necesito que aprendas a usar esta herramienta).
            </p>


            <p>
                Así que, dale!! ¡Únete a la fuerza y salvemos al mundo del lado oscuro!
            </p>
        </div>
    );
}

export default ProyectoHormiga;
