import axios from 'axios';
import { auth } from './../../firebase.js';

export const handleLikeComentario = async (anuncioId, comentarioId, isLike, setComments, userCommentInteractions, setUserCommentInteractions) => {
    try {
        const token = await auth.currentUser.getIdToken();
        const recaptchaToken = await window.grecaptcha.execute('6LfMfAsqAAAAAJlMEX16BxL8_PU0CIC-vMlyTsvZ', { action: 'submit' });

        await axios.post(
            'https://us-central1-hormiga-631f9.cloudfunctions.net/likeComentario',
            { anuncioId, comentarioId, isLike, recaptchaToken },
            { headers: { Authorization: `Bearer ${token}` } }
        );

        setComments(prevComments => {
            const updatedComments = prevComments[anuncioId].map(comment => {
                if (comment.id === comentarioId) {
                    const liked = userCommentInteractions[comentarioId] === 'like';
                    const disliked = userCommentInteractions[comentarioId] === 'dislike';
                    return {
                        ...comment,
                        likes: liked ? comment.likes - 1 : isLike ? comment.likes + 1 : comment.likes,
                        dislikes: disliked ? comment.dislikes - 1 : !isLike ? comment.dislikes + 1 : comment.dislikes,
                        userInteraction: isLike ? 'like' : 'dislike'
                    };
                }
                return comment;
            });

            return {
                ...prevComments,
                [anuncioId]: updatedComments
            };
        });

        setUserCommentInteractions(prevInteractions => ({
            ...prevInteractions,
            [comentarioId]: isLike ? 'like' : 'dislike'
        }));
    } catch (error) {
        console.error('Error al registrar la interacción:', error);
    }
};
