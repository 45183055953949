import React, { useState, useEffect } from 'react';
import { auth } from './../../firebase.js';
import './../../style/pages/profile.scss';
import Viculado from './../../assets/images/enlace.png';
import Abrir from './../../assets/images/abierto.png';
import StarRating from './starRatings.js';
import { handleDelete } from '../PublicationsList/handleDelete.js';
import { fetchUserAds } from '../PublicationsList/fetchUserAds.js';
import { fetchUserRating } from '../PublicationsList/userRating.js';

const Profile = () => {
    const [user, setUser] = useState(null);
    const [userAds, setUserAds] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [notificationMessage, setNotificationMessage] = useState('');
    const [userRating, setUserRating] = useState({ averageRating: 'N/A', ratingCount: 'N/A' });
    const [isCopying, setIsCopying] = useState(false);

    useEffect(() => {
        const loadUserData = async (currentUser) => {
            setIsLoading(true);
            try {
                await fetchUserRating(currentUser.uid, setUserRating, setNotificationMessage);
                await fetchUserAds(currentUser.uid, setUserAds, setIsLoading, setNotificationMessage);
            } catch (error) {
                console.error('Error fetching user data:', error);
                setNotificationMessage(`Error al obtener los datos del usuario: ${error.message}`);
            } finally {
                setIsLoading(false);
            }
        };

        const unsubscribeAuth = auth.onAuthStateChanged(async (currentUser) => {
            if (currentUser) {
                setUser(currentUser);
                await loadUserData(currentUser);
            } else {
                setUser(null);
                setIsLoading(false);
                setNotificationMessage('Por favor, inicia sesión para ver tu perfil.');
            }
        });

        return () => unsubscribeAuth();
    }, []);

    const copyToClipboard = (id) => {
        if (isCopying) return;

        const url = `https://www.hormigadapp.com/publications-list/${id}`;

        setIsCopying(true);
        navigator.clipboard.writeText(url)
            .then(() => {
                setNotificationMessage('Enlace copiado');
                setTimeout(() => {
                    setIsCopying(false);
                }, 3000);
            })
            .catch((error) => {
                setNotificationMessage(`Error al copiar el enlace: ${error}`);
                setIsCopying(false);
            });
    };

    const openLinkInNewTab = (id) => {
        const url = `https://www.hormigadapp.com/publications-list/${id}`;
        window.open(url, '_blank', 'noopener,noreferrer');
    };

    const handleDeleteAd = async (adId) => {
        setIsLoading(true);
        try {
            await handleDelete(adId, () => fetchUserAds(user.uid, setUserAds, setIsLoading, setNotificationMessage), setNotificationMessage, setIsLoading);
        } catch (error) {
            console.error('Error deleting ad:', error);
            setNotificationMessage('Error al eliminar el anuncio.');
        }
    };

    return (
        <div className="user-ads-container">
            {isLoading ? (
                <div className="loading-animation">
                    <div className="spinner"></div>
                </div>
            ) : (
                <div className="profile-content">
                    <h3>Tu perfil de Usuario</h3>
                    {notificationMessage && (
                        <div className="alert" onClick={() => setNotificationMessage('')}>
                            {notificationMessage}
                        </div>
                    )}
                    {user ? (
                        <>
                            <div className='card'>
                                <h4>
                                    Reputación: <StarRating rating={parseFloat(userRating.averageRating) || 0} />
                                </h4>
                                <span>
                                    Has recibido{' '}
                                    <span style={{ color: '#F0B90B' }}>{userRating.ratingCount}</span>{' '}
                                    {userRating.ratingCount === 1 ? 'calificación' : 'calificaciones'}
                                </span>
                                <br />
                                <span>Tu puntaje promedio es de{' '}<span style={{ color: '#F0B90B' }}>{userRating.averageRating} </span>estrellas</span>
                            </div>

                            <h3>Tus Anuncios Publicados:</h3>
                            <div className="user-ads-list">
                                {userAds.length === 0 ? (
                                    <p>No tienes anuncios publicados.</p>
                                ) : (
                                    userAds.map(ad => (
                                        <div key={ad.id} className="card">
                                            <div className='user-travel'>
                                                <span>Origen: {ad.city}, {ad.province}, {ad.country}</span>
                                                <span>Destino: {ad.destinationCity}, {ad.destinationProvince}, {ad.destinationCountry}</span>
                                                <span>Salida: {ad.departureDate}</span>
                                                <span>Llegada: {ad.arrivalDate}</span>
                                                <span>Descripción: {ad.descriptionItem}</span>
                                                <span>Valor de carga max: <span style={{ color: '#00bfff' }}>{ad.maxLoadValue}</span></span>
                                                <span>Recompensa: <span style={{ color: '#85bb65' }}>{ad.reward}</span></span>
                                                <span>Acción: <span style={{ color: '#F0B90B' }}>{ad.option}</span></span>
                                            </div>
                                            <div className="button-container">
                                                <button 
                                                    className='delete-button' 
                                                    onClick={() => handleDeleteAd(ad.id)}
                                                    disabled={isLoading} // Disable the button while loading
                                                >
                                                    Eliminar Anuncio
                                                </button>
                                                <button
                                                    className={`copy-enlace ${isCopying ? 'copying' : ''}`}
                                                    onClick={() => copyToClipboard(ad.id)}
                                                    title='Copiar enlace'
                                                >
                                                    <img src={Viculado} alt="Copiar enlace" style={{ width: '25px', height: '25px' }} />
                                                </button>
                                                <button
                                                    className="copy-enlace"
                                                    onClick={() => openLinkInNewTab(ad.id)}
                                                    title='Abrir en nueva pestaña'
                                                >
                                                    <img src={Abrir} alt="Abrir nueva pestaña" style={{ width: '25px', height: '25px' }} />
                                                </button>
                                            </div>
                                        </div>
                                    ))
                                )}
                            </div>
                        </>
                    ) : (
                        <p>{notificationMessage}</p>
                    )}
                </div>
            )}
        </div>
    );
};

export default Profile;
