import React, { useState } from 'react';

const Footer = () => {
    const [showTerms, setShowTerms] = useState(false); // Estado para controlar la visualización de los términos y condiciones

    return (
        <div className="footer">
            <h3 className="footer-desc" onClick={() => setShowTerms(!showTerms)}>Términos y condiciones</h3>
            {showTerms && (
                <div className="terms-text">
                    <h2>Términos y condiciones para usar Hormiga DApp</h2>
                    <p>Estos términos y condiciones rigen el uso de la aplicación Hormiga (Hormiga DApp) y establecen las condiciones bajo las cuales los usuarios pueden acceder y utilizar la DApp. Por favor, lea estos términos cuidadosamente antes de usar la DApp. Al acceder y usar la DApp, usted acepta quedar sujeto a estos términos. Si no está de acuerdo con estos Términos, le recomendamos que no utilice la DApp.</p>

                    <h3>Descripción de la DApp</h3>
                    <p>La Hormiga DApp es una aplicación descentralizada basada en la cadena de bloques Polygon que permite a los usuarios crear, administrar y rastrear remitos creados en formato de tokens no fungibles (NFT). Los siguientes puntos detallan las características clave de la DApp:</p>

                    <ul>
                        <li>Contratos inteligentes verificados y código fuente abierto</li>
                        <p>Los contratos inteligentes utilizados en la DApp son privados, no son de código abierto. Sin embargo, solo serán publicados en caso de una emergencia, como podría ser la censura del dominio https.</p>
                        
                        <li>Responsabilidades del usuario</li>
                        <p>Al usar la DApp, el usuario acepta:</p>
                        <ul>
                            <li>Usar la DApp solo para fines legítimos y de acuerdo con las leyes y regulaciones aplicables en su jurisdicción.</li>
                            <li>No intentar eludir o comprometer la seguridad de la DApp o la cadena de bloques Polygon.</li>
                            <li>No utilizar la DApp para actividades ilegales o fraudulentas.</li>
                        </ul>

                        <li>Descargo de responsabilidad</li>
                        <p>El creador de la DApp y cualquier entidad relacionada no son responsables de:</p>
                        <ul>
                            <li>Pérdida de fondos, daños o perjuicios que los usuarios puedan sufrir como resultado del uso de la DApp.</li>
                            <li>Transacciones o acuerdos entre los usuarios de la DApp.</li>
                            <li>Errores, fallos o interrupciones en la DApp debido a circunstancias fuera del control del creador.</li>
                            <li>Infracciones de estos Términos por parte de los usuarios.</li>
                            <li>Cambios en el funcionamiento de la DApp en el futuro.</li>
                        </ul>

                        <li>Propiedad intelectual</li>
                        <p>Todos los derechos de propiedad intelectual relacionados con la DApp, incluido el código fuente, diseño y marca, son propiedad exclusiva del Creador. Los usuarios no tienen derecho a utilizar, copiar o modificar estos elementos sin el consentimiento expreso del Creador.</p>

                        <li>Modificaciones</li>
                        <p>El Creador se reserva el derecho de modificar estos Términos en cualquier momento sin previo aviso. Se recomienda a los usuarios revisar periódicamente estos Términos para mantenerse informados de las actualizaciones. El uso continuado de la DApp después de cualquier modificación constituirá la aceptación de los Términos modificados.</p>

                        <li>Ley aplicable</li>
                        <p>Estos Términos se regirán e interpretarán de acuerdo con las leyes de la jurisdicción aplicable. Cualquier disputa que surja en relación con estos Términos estará sujeta a la jurisdicción exclusiva de los tribunales de la jurisdicción aplicable.</p>
                    </ul>

                    <p>Al utilizar la DApp, usted reconoce y acepta estos Términos y Condiciones de Uso. Si no está de acuerdo con estos términos, por favor no utilice la DApp.</p>

                    <p>Mariano Privitera</p>
                    <p>October 20, 2024</p>
                </div>
            )}
        </div>
    );
}

export default Footer;
