import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { Web3ReactProvider } from "@web3-react/core";
import Web3 from "web3";
import Hormiga from "./pages/hormiga-marketplace/index";
import "./App.scss";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "react-toastify/dist/ReactToastify.css";
import PublicationsList from "./pages/PublicationsList";
import CreatePublication from "./pages/create-publication";
import MyPage from "./pages/myPage";
import NavBar from "./components/NavBar";
import NFTMinting from "./pages/mint";
import Footer from "./components/Footer";
import Instructions from "./pages/instructions";
import ProyectoHormiga from "./pages/proyecto-hormiga";
import Swap from "./pages/swap";
import AdDetails from './pages/adDetails';
import Inbox from './pages/inbox';
import Notifications from "./pages/notifications";
import UserAds from "./pages/profile";

function App() {
  function getLibrary(provider) {
    return new Web3(provider);
  }
  return (
    <Web3ReactProvider getLibrary={getLibrary}>
      <div className="App">
        <NavBar />
        <Router>
          <Routes>
            <Route path="/" exact element={<Hormiga />} />
            <Route path="/mint" exact element={<NFTMinting />} />
            <Route path="/my-page" exact element={<MyPage />} />
            <Route path="/create-publication" exact element={<CreatePublication />} />
            <Route path="/publications-list" exact element={<PublicationsList />} />
            <Route path="/publications-list/:id" element={<AdDetails />} />
            <Route path="/nft-marketplace" exact element={<Hormiga />} />
            <Route path="/instructions" exact element={<Instructions />} />
            <Route path="/proyecto-hormiga" exact element={<ProyectoHormiga />} />
            <Route path="/swap" exact element={<Swap />} />
            <Route path="/inbox" exact element={<Inbox />} />
            <Route path="/notifications" exact element={<Notifications />} />
            <Route path="/profile" exact element={<UserAds />} />
          </Routes>
        </Router>
        <Footer />
      </div>
    </Web3ReactProvider>
  );
}

export default App;
