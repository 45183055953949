import { useState, useCallback, useEffect, useRef } from "react";
import { useWeb3React } from "@web3-react/core";
import Logo from '../assets/images/logo144.png';
import logoHTC from '../assets/images/metamasklogo.png';
import { getNativeBalanceOf, getFERPoolBalance, getFEVPoolBalance, connect, disconnect, humanReadableAccount, getFrozenHTCBalance, getFrozenUSDTBalance } from "../web3/index";
import './../style/components/navbar.scss';
import { googleProvider } from './../firebase';
import { getAuth, signInWithPopup } from "firebase/auth";
import logoGoogle from "../assets/images/logogoogle.png";
import { useAuth } from './../components/authContex';

const StatItem = ({ title, value, total }) => {
  const percentage = ((Number(value) / Number(total)) * 100).toFixed(2);

  const formattedValue = Number(Number(value).toFixed(6)).toLocaleString('de-DE', {
    minimumIntegerDigits: 1,
    minimumFractionDigits: 0,
    maximumFractionDigits: 6
  });

  return (
    <div className="stat-item">
      <h3 className="stat-title">{title}:</h3>
      {value !== null && (
        <>
          <span className="stat-value">{formattedValue} HTC</span>
          <span className="stat-percentage">({percentage}%)</span>
        </>
      )}
    </div>
  );
};

const NavBar = () => {
  const { active, account, activate, deactivate } = useWeb3React();
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [balance, setBalance] = useState(null);
  const [fevTokenBalance, setFEVTokenBalance] = useState(null);
  const [ferTokenBalance, setFERTokenBalance] = useState(null);
  const [frozenHTCBalance, setFrozenHTCBalance] = useState(null);
  const [frozenUSDTBalance, setFrozenUSDTBalance] = useState(null);
  const sidebarRef = useRef(null);
  const [cardVisible, setCardVisible] = useState(false);
  const { user, setUser } = useAuth();
  const [notificationMessage, setNotificationMessage] = useState('');

  const handleCardToggle = () => {
    setCardVisible(!cardVisible);
  };

  const getBalances = useCallback(async () => {
    if (active) {
      const retrievedBalance = await getNativeBalanceOf(account);
      setBalance(retrievedBalance);

      const fevBalance = await getFEVPoolBalance(account);
      setFEVTokenBalance(fevBalance);

      const ferBalance = await getFERPoolBalance(account);
      setFERTokenBalance(ferBalance);

      const htcBalance = await getFrozenHTCBalance();
      setFrozenHTCBalance(htcBalance);

      const usdtBalance = await getFrozenUSDTBalance();
      setFrozenUSDTBalance(usdtBalance);
    } else {
      setBalance(null);
      setFEVTokenBalance(null);
      setFERTokenBalance(null);
      setFrozenHTCBalance(null);
      setFrozenUSDTBalance(null);
    }
  }, [active, account]);

  useEffect(() => {
    getBalances();
  }, [account, active, getBalances]);

  useEffect(() => {
    const connectWalletOnLoad = async () => {
      if (localStorage.getItem('isWalletConnected') === 'true') {
        await connect(activate);
      }
    };
    connectWalletOnLoad();
  }, [activate]);

  const handleSidebarClick = (e) => {
    if (sidebarRef.current && !sidebarRef.current.contains(e.target)) {
      setIsMenuOpen(false);
    }
  };

  useEffect(() => {
    if (isMenuOpen) {
      document.addEventListener('mousedown', handleSidebarClick);
    } else {
      document.removeEventListener('mousedown', handleSidebarClick);
    }

    return () => {
      document.removeEventListener('mousedown', handleSidebarClick);
    };
  }, [isMenuOpen]);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const handleConnectWallet = async () => {
    if (active) {
      await disconnect(deactivate);
      localStorage.removeItem('isWalletConnected');
    } else {
      await connect(activate);
      localStorage.setItem('isWalletConnected', 'true');
    }
  };

  const signInWithProvider = (provider) => {
    const auth = getAuth();
    signInWithPopup(auth, provider)
      .then((result) => {
        console.log(result.user);
        setUser(result.user);
        setNotificationMessage(`Bienvenido ${result.user.displayName}`);
      })
      .catch((error) => console.error(`Error logging in with ${provider.providerId}:`, error));
  };

  const signOutUser = () => {
    const authInstance = getAuth();
    authInstance.signOut()
      .then(() => {
        console.log("Usuario cerró sesión exitosamente!");
        setUser(null);
        setNotificationMessage('Nos vemos!');
      })
      .catch((error) => {
        console.error("Error al cerrar sesión", error);
      });
  };

  const totalSupply = 7000000000000;

  return (
    <div>
      <div className="component-navbar">
        <div className="navigation">
          <div className="logo-container"
            onMouseEnter={handleCardToggle}
            onMouseLeave={handleCardToggle}>
            <a className="nav-logo" href="/">
              <img src={Logo} alt="Ant logo" />HORMIGA
            </a>
            {cardVisible && (
              <div className="stat-card">
                <h3>Datos globales:</h3>
                <StatItem
                  title="Valor declarado congelado"
                  value={fevTokenBalance}
                  total={totalSupply}
                />
                <StatItem
                  title="Recompensas congeladas"
                  value={ferTokenBalance}
                  total={totalSupply}
                />
                {ferTokenBalance !== null && fevTokenBalance !== null && (
                  <StatItem
                    title="Fondos no utilizados"
                    value={totalSupply - (Number(ferTokenBalance) + Number(fevTokenBalance))}
                    total={totalSupply}
                  />
                )}
                <StatItem
                  title="Fondos de HTC en SWAP"
                  value={frozenHTCBalance}
                  total={totalSupply}
                />
                <StatItem
                  title="Fondos de USDT en SWAP"
                  value={frozenUSDTBalance}
                  total={totalSupply}
                />
              </div>
            )}
          </div>

          <div className="menu-items">
            <button
              className="hamburger-button"
              onClick={toggleMenu}
              aria-expanded={isMenuOpen}
              aria-label="Menú principal"
            >
              &#9776;
            </button>

            <div className="desktop-menu">
              <a href="/profile" className="nav-links">👤</a>
              <a href="/notifications" className="nav-links">&#128276;</a>
              <a href="/inbox" className="nav-links">&#9993;</a>
              <a href="/create-publication" className="nav-links">Publicar</a>
              <a href="/publications-list" className="nav-links">&#128269;</a>
              <a href="/mint" className="nav-links">Acuñar</a>
              <a href="/my-page" className="nav-links">NFTs</a>
              <a href="/swap" className="nav-links">SWAP</a>
            </div>

            <button className='component-buttonM' onClick={handleConnectWallet}>
              {active && humanReadableAccount(account) ? `${humanReadableAccount(account)} (${balance !== null ? (+balance).toFixed(6) : null} HTC)` : "Conectar a Web3"}
              <img className="logometamask" src={logoHTC} alt="Logo HTC" />
            </button>
          </div>
        </div>

        <div
          className={`sidebar ${isMenuOpen ? "show" : ""}`}
          ref={sidebarRef}
          aria-hidden={!isMenuOpen}
        >
           <a href="/profile" className="nav-links">Mi perfil 👤</a>
          <a href="/notifications" className="nav-links">Notificaciones &#128276;</a>
          <a href="/inbox" className="nav-links">Bandeja de entrada &#9993;</a>
          <a href="/create-publication" className="nav-links">Publicar Anuncio</a>
          <a href="/publications-list" className="nav-links">Buscar Anuncios &#128269;</a>
          <a href="/mint" className="nav-links">Crear Remito NFT</a>
          <a href="/my-page" className="nav-links">NFTs</a>
          <a href="/swap" className="nav-links">SWAP</a>
        </div>

        {isMenuOpen && <div className="overlay" onClick={toggleMenu}></div>}
      </div>

      <div className="auth-container">
        {user ? (
          <>
            <button className="auth-button logout" onClick={signOutUser}>
              Cerrar sesión
            </button>
            <p className="small-blue-text">Conectado como: {getAuth().currentUser?.displayName}</p>
          </>
        ) : (
          <>
            <div className="auth-buttons-container">
              <button className="auth-button google" onClick={() => signInWithProvider(googleProvider)}>
                <span>Inicia sesión con:</span>
                <img src={logoGoogle} alt="Google logo" className="auth-logo" />
              </button>
            </div>
          </>
        )}
      </div>

      {notificationMessage && (
        <div className="alert" onClick={() => {
          setNotificationMessage('');
        }}>
          {notificationMessage}
        </div>
      )}
    </div>
  );
};

export default NavBar;
