import axios from 'axios';
import { auth } from './../../firebase.js';

export const handleLikeAnuncio = async (anuncioId, isLike, userInteractions, setUserInteractions, setFilteredPublications, setNotificationMessage) => {
  try {
    const token = await auth.currentUser.getIdToken();
    const recaptchaToken = await window.grecaptcha.execute('6LfMfAsqAAAAAJlMEX16BxL8_PU0CIC-vMlyTsvZ', { action: 'submit' });
    await axios.post(
      'https://us-central1-hormiga-631f9.cloudfunctions.net/likeAnuncio',
      { anuncioId, isLike, recaptchaToken },
      { headers: { Authorization: `Bearer ${token}` } }
    );

    setFilteredPublications(prevPublications => {
      const updatedPublications = prevPublications.map(pub => {
        if (pub.id === anuncioId) {
          const liked = userInteractions[anuncioId] === 'like';
          const disliked = userInteractions[anuncioId] === 'dislike';
          return {
            ...pub,
            likes: liked ? pub.likes - 1 : isLike ? pub.likes + 1 : pub.likes,
            dislikes: disliked ? pub.dislikes - 1 : !isLike ? pub.dislikes + 1 : pub.dislikes
          };
        }
        return pub;
      });

      return updatedPublications.sort((a, b) => b.likes - a.likes);
    });

    setUserInteractions(prevInteractions => ({
      ...prevInteractions,
      [anuncioId]: isLike ? 'like' : 'dislike'
    }));
  } catch (error) {
    setNotificationMessage('Error registrando la interacción.');
    console.error('Error registrando la interacción:', error);
  }
};
