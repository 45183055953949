
import axios from 'axios';

export const fetchUserAds = async (userId, setUserAds, setIsLoading, setNotificationMessage) => {
    setIsLoading(true);
    try {
        const response = await axios.get('https://us-central1-hormiga-631f9.cloudfunctions.net/getAnunciosByUserId', {
            params: { userId }
        });
        setUserAds(response.data);
    } catch (error) {
        setNotificationMessage('Error al obtener los anuncios del usuario.');
    } finally {
        setIsLoading(false);
    }
};
