import React, { useState, useEffect } from 'react';
import { useWeb3React } from "@web3-react/core";
import '../../style/pages/myPage.scss';
import NFTItem from "./../../components/NFTItem";
import { getNFTs } from './../../web3';


function MyPage() {
    const { account } = useWeb3React();
    const [nfts, setNfts] = useState([]);
    const [filter, setFilter] = useState('created');
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [isDescendingOrder, setIsDescendingOrder] = useState(true);
    const toggleOrder = () => {
        setIsDescendingOrder(!isDescendingOrder);
    };

    useEffect(() => {
        async function loadNFTs() {
            setLoading(true);
            setError(null);
            try {
                if (account) {
                    const fetchedNFTs = await getNFTs(account, filter);
                    setNfts(fetchedNFTs);
                }
            } catch (err) {
                console.error("Error charging NFTs:", err);
                setError("Error al cargar NFT. Por favor, inténtelo de nuevo más tarde.");
            } finally {
                setLoading(false);
            }
        }

        loadNFTs();
    }, [account, filter]);


    const renderedNFTs = isDescendingOrder ? [...nfts].reverse() : nfts;

    return (
        <div className="mypage-container">
           
            <div className="filter-bar"><button onClick={toggleOrder}>
                {isDescendingOrder ? "↓↑" : "↑↓"}
            </button>
                <label>Filtrar por:</label>
                <select value={filter} onChange={e => setFilter(e.target.value)}>


                    <option value="own">Remitos en cartera</option>

                    <option value="created">Remitos creados</option>

                    <option value="liberating">Remitos pendientes de firma</option>

                    <option value="finishedLiberating">Remitos liberados </option>

                    <option value="refunded">Remitos reembolsados </option>

                    <option value="finishedHolder">Remitos liberados en mi cuenta</option>




                </select>
            </div>

            {loading && (
                <div className="loading-indicator">
                    <p>Cargando...</p>
                </div>
            )}

            {error && (
                <div className="error-message">
                    <p>{error}</p>
                </div>
            )}

            <section className="nft-list">
                {nfts.length === 0 && !loading && !error && (
                    <p className="no-nfts-message">No se encontraron NFT con los filtros seleccionados.</p>
                )}

                {renderedNFTs.map(tokenId => (
                    <NFTItem key={tokenId} tokenId={tokenId} filterType={filter} />
                ))}

            </section>
        </div>
    );
}

export default MyPage;
