import React from 'react';
import PropTypes from 'prop-types';

const StarRating = ({ rating }) => {
    const stars = [];
    for (let i = 1; i <= 5; i++) {
        if (i <= Math.floor(rating)) {
            stars.push(<span key={i} className="star filled">★</span>);
        } else if (i === Math.ceil(rating) && !Number.isInteger(rating)) {
            stars.push(<span key={i} className="star half">★</span>);
        } else {
            stars.push(<span key={i} className="star">☆</span>);
        }
    }
    return <div className="star-rating">{stars}</div>;
};

StarRating.propTypes = {
    rating: PropTypes.number.isRequired
};

export default StarRating;
