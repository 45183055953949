import axios from 'axios';
import { auth } from './../../firebase.js';

export const handleComment = async (id, commentText, setCommentText, fetchComments, setNotificationMessage, setIsSubmitting, setIsLoading) => {
  if (commentText.trim() === '') {
    setNotificationMessage('El comentario no puede estar vacío.');
    return;
  }

  setIsSubmitting(true);
  setIsLoading(true);

  try {
    const token = await auth.currentUser.getIdToken();
    const recaptchaToken = await window.grecaptcha.execute('6LfMfAsqAAAAAJlMEX16BxL8_PU0CIC-vMlyTsvZ', { action: 'submit' });

    await axios.post('https://us-central1-hormiga-631f9.cloudfunctions.net/createComentario', {
      anuncioId: id,
      text: commentText,
      recaptchaToken
    }, {
      headers: {
        'Authorization': `Bearer ${token}`
      }
    });

    setNotificationMessage('Comentario enviado con éxito');
    setCommentText('');
    fetchComments(id);
  } catch (error) {
    setNotificationMessage('Error al enviar el comentario. Intenta nuevamente.');
  } finally {
    setIsSubmitting(false);
    setIsLoading(false);
  }
};
