import { InjectedConnector } from "@web3-react/injected-connector";
const Web3 = require('web3');
const BigNumber = require("bignumber.js");
const web3 = new Web3(window.ethereum);

// Configuración de las direcciones y ABI de los contratos/////////////POLYGON-MAINET/////////////////////////
export const RemitoHormigaNFTcontractAddress = "0x73822843288FD1A1AB207f63c4f588BEB07028f0"; 
export const HormigaTokenContractAddress = "0xda4FDbB2C31d8805a08736A76DF2afcF43Be7Ee1"; 

export const RemitoHormigaContractABI = require('./artifacts/contracts/RemitoHormigaNFT.sol/RemitoHormigaNFT.json').abi;
export const HormigaTokenContractABI = require('./artifacts/contracts/NativeToken.sol/HormigaToken.json').abi;

// Crear instancias de los contratos
export const RemitoHormigaNFTcontract = new web3.eth.Contract(RemitoHormigaContractABI, RemitoHormigaNFTcontractAddress);
export const HormigaTokenContract = new web3.eth.Contract(HormigaTokenContractABI, HormigaTokenContractAddress);

// Solicitar acceso a la cuenta del usuario
export async function requestAccess() {
    const accounts = await window.ethereum.request({ method: 'eth_requestAccounts' });
    return accounts[0];
}

// Conexión y desconexión
const injected = new InjectedConnector({ supportedNetworks: [137] });
const connect = async (activate) => {
    try {
        await activate(injected);
    } catch (error) {
        console.error("Error connecting:", error);
    }
};

const disconnect = async (deactivate) => {try { await deactivate(); } catch (error) { console.error("Error disconnecting:", error);}};

const humanReadableAccount = (_account) => {return _account.slice(0, 6) + "..." + _account.slice(_account.length - 4);};

const DECIMALS = 6; 

function fromTokenUnits(bigNumberValue) {return bigNumberValue.dividedBy(new BigNumber(10).pow(DECIMALS));}

function toTokenUnits(value) {return new BigNumber(value).multipliedBy(new BigNumber(10).pow(DECIMALS));}


// Funciones de HormigaToken
const getNativeBalanceOf = async (address) => {
    const balance = await HormigaTokenContract.methods.balanceOf(address).call();
    return fromTokenUnits(new BigNumber(balance));
};


const setApproved = async (amount, _account) => {
    const amountInWei = toTokenUnits(amount).toFixed().toString();
    return await HormigaTokenContract.methods.approve(RemitoHormigaNFTcontractAddress, amountInWei).send({ from: _account });
};


async function getAdjustedGasPrice() {
    const currentGasPrice = await web3.eth.getGasPrice();
    console.log("Precio actual del gas:", currentGasPrice);
    const adjustedGasPrice = new BigNumber(currentGasPrice).multipliedBy(4).integerValue(BigNumber.ROUND_DOWN).toFixed();
    console.log("Precio ajustado del gas:", adjustedGasPrice);
    return adjustedGasPrice.toString();
}

//..

const mint = async (
    valorDeclarado,
    recompensa,
    tiempoLimite,
    liberatingWallet,
    metadataURI,
    account,
    origen,
    destino
) => {
    console.log("Intentando minting remito con los siguientes datos:");
    console.log("valorDeclarado:", valorDeclarado);
    console.log("recompensa:", recompensa);
    console.log("tiempoLimite:", tiempoLimite);
    console.log("liberatingWallet:", liberatingWallet);
    console.log("metadataURI:", metadataURI); 
    console.log("account:", account);
    console.log("origen:", origen);
    console.log("destino:", destino);

    try {
   
        const tiempoLimiteUnixTimestamp = new Date(tiempoLimite).getTime() / 1000;
        
        const adjustedGasPrice = await getAdjustedGasPrice(); // Obtener el gasPrice ajustado

        const transaction = await RemitoHormigaNFTcontract.methods
            .mint(
                valorDeclarado,
                recompensa,
                tiempoLimiteUnixTimestamp,
                liberatingWallet,
                metadataURI, 
                origen,
                destino
            )
            .send({ 
                from: account, 
                gasPrice: adjustedGasPrice // Usar el gasPrice ajustado
            });

        console.log("Remito creado exitosamente:", transaction);
        return transaction;
    } catch (error) {
        console.error("Error creando remito:", error);
        throw error;
    }
};

// ...

async function deliver(tokenId) {
    const fromAddress = await requestAccess();
    const adjustedGasPrice = await getAdjustedGasPrice();
    return RemitoHormigaNFTcontract.methods.deliver(tokenId).send({ from: fromAddress, gasPrice: adjustedGasPrice });
}

async function claimRefund(tokenId) {
    const fromAddress = await requestAccess();
    const adjustedGasPrice = await getAdjustedGasPrice();
    return RemitoHormigaNFTcontract.methods.claimRefund(tokenId).send({ from: fromAddress, gasPrice: adjustedGasPrice });
}

async function transferNFT(tokenId, toAddress) {
    const fromAddress = await requestAccess();
    const adjustedGasPrice = await getAdjustedGasPrice();
    return RemitoHormigaNFTcontract.methods.transferFrom(fromAddress, toAddress, tokenId).send({ from: fromAddress, gasPrice: adjustedGasPrice });
}
////////////--------//FUNCIONES DE VISTA//---------////////////

async function getRemitoById(tokenId) {
    return RemitoHormigaNFTcontract.methods.remitos(tokenId).call();
}

async function getOriginalMinter(tokenId) {
    return RemitoHormigaNFTcontract.methods.originalMinters(tokenId).call();
}

async function getFEVPoolBalance() {
    return RemitoHormigaNFTcontract.methods.FEVPoolBalance().call();
}

async function getFERPoolBalance() {
    return RemitoHormigaNFTcontract.methods.FERPoolBalance().call();
}

async function getTokenURI(tokenId) {
    return RemitoHormigaNFTcontract.methods.tokenURI(tokenId).call();
}

async function getWalletOfOwner(ownerAddress) {
    return RemitoHormigaNFTcontract.methods.walletOfOwner(ownerAddress).call();
}

async function getNFTs(account, filter) {
    return RemitoHormigaNFTcontract.methods.getNFTs(account, filter).call(); 
}

async function getOwnerOfNFT(tokenId) {
    return RemitoHormigaNFTcontract.methods.ownerOf(tokenId).call();
}
async function searchRemitosByOrigen(keyword) {
    return RemitoHormigaNFTcontract.methods.searchRemitosByOrigen(keyword).call();
}

async function searchRemitosByDestino(keyword) {
    return RemitoHormigaNFTcontract.methods.searchRemitosByDestino(keyword).call();
}

///////////////////----------------------///SWAP/////----------------//////////////////////////////

export const SwapContractAddress = "0xd5EcAE35B0E385459eddF1b07d4F1A1927963789";
export const SwapContractABI = require('./artifacts/contracts/TokenSwap.sol/TokenSwap.json').abi;
export const SwapContract = new web3.eth.Contract(SwapContractABI, SwapContractAddress);

const setApprovedHTC = async (amount, _account) => {
    const amountInWei = toTokenUnits(amount).toFixed().toString();
    return await HormigaTokenContract.methods.approve(SwapContractAddress, amountInWei).send({ from: _account });
};

// Función para intercambiar HTC por USDT
const swapHTCtoUSDT = async (htcAmount, account) => {
    const usdtAmount = htcAmount; // 1 HTC = 1 USDT for simplicity
    await SwapContract.methods.swapHTCForUSDT(htcAmount).send({ from: account });
    console.log(`Swapped ${htcAmount} HTC for ${usdtAmount} USDT.`);
};

// Función para intercambiar USDT por HTC
const swapUSDTtoHTC = async (usdtAmount, account) => {
    const htcAmount = usdtAmount; // 1 USDT = 1 HTC for simplicity
    await SwapContract.methods.swapUSDTForHTC(usdtAmount).send({ from: account });
    console.log(`Swapped ${usdtAmount} USDT for ${htcAmount} HTC.`);
};

// Funciones de vista
async function getFrozenHTCBalance() {
    return SwapContract.methods.getFrozenHTCBalance().call();
}
async function getFrozenUSDTBalance() {
    return SwapContract.methods.getFrozenUSDTBalance().call();
}

const usdtAbi = [
    {
      "constant": false,
      "inputs": [
        {
          "name": "_spender",
          "type": "address"
        },
        {
          "name": "_value",
          "type": "uint256"
        }
      ],
      "name": "approve",
      "outputs": [
        {
          "name": "",
          "type": "bool"
        }
      ],
      "type": "function"
    },
    {
      "inputs": [
        {
          "internalType": "address",
          "name": "owner",
          "type": "address"
        },
        {
          "internalType": "address",
          "name": "spender",
          "type": "address"
        }
      ],
      "name": "allowance",
      "outputs": [
        {
          "internalType": "uint256",
          "name": "",
          "type": "uint256"
        }
      ],
      "stateMutability": "view",
      "type": "function"
    }
  ];
  
  const usdtAddress = '0xc2132d05d31c914a87c6611c10748aeb04b58e8f'; 
  const usdtContract = new web3.eth.Contract(usdtAbi, usdtAddress);

// Función para aprobar USDT
const setApprovedUSDT = async (amount, _account) => {
    const amountInWei = toTokenUnits(amount).toFixed().toString();
    return await usdtContract.methods.approve(SwapContractAddress, amountInWei).send({ from: _account });
};

// Dirección del contrato que gastará los tokens
const spenderAddress = '0xAc81a161064E32547c4761AD772D38312D26c4ad';
const amount = '1'; // Cantidad de USDT a aprobar en USDT, no en Wei

//////////////////////SWAP///////////////////////////////////////////SWAP/////////////////////




// Exportar las funciones
export {
    mint, 
    deliver,
    claimRefund,
    transferNFT,
    getRemitoById,
    getOriginalMinter,
    getFEVPoolBalance,
    getFERPoolBalance,
    getTokenURI,
    getWalletOfOwner,
    getNFTs,
    setApproved,
    getNativeBalanceOf,
    connect,
    disconnect,
    humanReadableAccount,
    getOwnerOfNFT,
    searchRemitosByOrigen,
    searchRemitosByDestino,

    ///////SWAP///////
    setApprovedHTC,
    swapHTCtoUSDT,
    getFrozenHTCBalance,
    getFrozenUSDTBalance,
    swapUSDTtoHTC,
    usdtContract,
    usdtAbi,
    usdtAddress,
    spenderAddress,
    amount,
    setApprovedUSDT
    
}

