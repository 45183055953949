import axios from 'axios';

export const fetchComments = async (anuncioId, setComments, setIsLoading) => {
  setIsLoading(true);
  try {
    const response = await axios.get('https://us-central1-hormiga-631f9.cloudfunctions.net/getComentarios', {
      params: { anuncioId },
    });

    const commentsWithUserInfo = response.data.map(comment => {
      const createdAt = new Date(comment.createdAt._seconds * 1000);
      const now = new Date();
      const diff = now - createdAt;
      const seconds = Math.floor(diff / 1000);
      let elapsed = '';
      if (seconds < 60) {
        elapsed = `${seconds} segundo${seconds !== 1 ? 's' : ''} atrás`;
      } else if (seconds < 3600) {
        const minutes = Math.floor(seconds / 60);
        elapsed = `${minutes} minuto${minutes !== 1 ? 's' : ''} atrás`;
      } else if (seconds < 86400) {
        const hours = Math.floor(seconds / 3600);
        elapsed = `${hours} hora${hours !== 1 ? 's' : ''} atrás`;
      } else {
        const days = Math.floor(seconds / 86400);
        elapsed = `${days} día${days !== 1 ? 's' : ''} atrás`;
      }
      return {
        ...comment,
        user: comment.userName,
        createdAt: elapsed,
        timestamp: createdAt.toLocaleString()
      };
    });

    setComments(prevComments => ({
      ...prevComments,
      [anuncioId]: commentsWithUserInfo
    }));
  } catch (error) {
    console.error('Error fetching comments:', error);
  } finally {
    setIsLoading(false);
  }
};
