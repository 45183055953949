import React from 'react';
import { Link } from 'react-router-dom';
import Box from '../../assets/images/boxuniverse500.png';
import Hormig from '../../assets/images/hormiga-dorada.png';
import Intagram from '../../assets/images/logoInstragram.png';
import Facebook from '../../assets/images/logofacebook.png';
import './../../style/pages/hormiga.scss';

const Hormiga = () => {
    return (
        <div className="page-container">

            <Link to="/mint">
                <img src={Box} alt="" className='ant-image' />
            </Link>

            <a href="./publications-list">
                <img src={Hormig} alt="" className='ant-hormig' />
            </a>

            <div className="text-content">
                <h2>
                    Gana dinero viajando!
                </h2>
                <p>Envía o lleva cosas a cualquier lugar del mundo!</p>
         
            <div>
            <iframe width="532" height="274" src="https://www.youtube.com/embed/m8j_ozEz42k" title="HormigaDAppVideo" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
            </div>
                <h4>
                    <a href='./instructions' >Instrucciones.</a>
                </h4>
            </div>


            <div className="socialIcon2">
                <a href="https://www.instagram.com/hormigadapp/" target="_blank" rel="noopener noreferrer">
                    <img src={Intagram} alt="Instagram" />
                </a>
            </div>

            <div className="socialIcon3">
                <a href="https://www.facebook.com/groups/910778200557892" target="_blank" rel="noopener noreferrer">
                    <img src={Facebook} alt="Telegram" />
                </a>
            </div>

        </div>
    );
}

export default Hormiga;
