import axios from 'axios';

export const fetchAdDetails = async (id, setAdDetails, setIsLoading, setNotificationMessage, fetchComments) => {
    setIsLoading(true);
    try {
        const response = await axios.get('https://us-central1-hormiga-631f9.cloudfunctions.net/getAnuncioById', {
            params: { id }
        });
        const data = response.data;
        setAdDetails({
            ...data,
            showPhone: false
        });
        await fetchComments(id, setAdDetails, setIsLoading);
    } catch (error) {
        if (error.response && error.response.status === 403) {
            setNotificationMessage('Usuario no autenticado, inicie sesión para ver los detalles.');
        } else {
            setNotificationMessage('Error al obtener los detalles del anuncio.');
        }
    } finally {
        setIsLoading(false);
    }
};
