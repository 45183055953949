import React, { useState, useEffect, useCallback } from 'react';
import { auth } from './../../firebase.js';
import axios from 'axios';
import StarRatingButtons from './../starsUser/index.js';
import StarRating from '../profile/starRatings.js';
import "../../style/components/publications.scss";
import Viculado from './../../assets/images/enlace.png';
import Abrir from './../../assets/images/abierto.png';
import { useNavigate } from 'react-router-dom';

import { handleLikeAnuncio } from './handleLikeAnuncio.js';
import { handleLikeComentario } from './handleLikeComentario.js';
import { fetchComments } from './fetchComments.js';
import { handleComment } from './handleComment.js';
import { handleDelete } from './handleDelete.js';
import { handleDeleteComment } from './handleDeleteComment.js';

function PublicationsList() {
  const [publications, setPublications] = useState([]);
  const [filteredPublications, setFilteredPublications] = useState([]);
  const [searchFields, setSearchFields] = useState({
    city: '',
    province: '',
    country: '',
    phone: '',
    maxLoadValue: '',
    destinationCity: '',
    destinationProvince: '',
    destinationCountry: '',
    option: '',
    reward: '',
  });
  const [notificationMessage, setNotificationMessage] = useState('');
  const [filter, setFilter] = useState('own');
  const [commentText, setCommentText] = useState('');
  const [comments, setComments] = useState({});
  const [showCommentsMap, setShowCommentsMap] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [userInteractions, setUserInteractions] = useState({});
  const [userCommentInteractions, setUserCommentInteractions] = useState({});
  const [isCopying, setIsCopying] = useState(false);
  const [showRatingButtons, setShowRatingButtons] = useState({});
  const [showPhoneNumbers, setShowPhoneNumbers] = useState({});
  
  const navigate = useNavigate();

  const toggleRatingButtonsVisibility = (id) => {
    setShowRatingButtons((prevShowRatingButtons) => ({
      ...prevShowRatingButtons,
      [id]: !prevShowRatingButtons[id],
    }));
  };

  const togglePhoneVisibility = (id) => {
    setShowPhoneNumbers((prevShowPhoneNumbers) => ({
      ...prevShowPhoneNumbers,
      [id]: !prevShowPhoneNumbers[id],
    }));
  };

  useEffect(() => {
    window.grecaptcha.ready(() => {
      // reCAPTCHA está listo
    });
  }, []);

  const copyToClipboard = (id) => {
    if (isCopying) return;
    const url = `https://www.hormigadapp.com/publications-list/${id}`;
    setIsCopying(true);
    navigator.clipboard.writeText(url)
      .then(() => {
        setNotificationMessage('Enlace copiado');
        setTimeout(() => {
          setIsCopying(false);
        }, 3000);
      })
      .catch((error) => {
        console.error('Error al copiar el enlace: ', error);
        setIsCopying(false);
      });
  };

  const openLinkInNewTab = (id) => {
    navigate(`/publications-list/${id}`);
  };

  const getButtonClassName = (id, type) => {
    const interaction = userInteractions[id];
    if (interaction === type) {
      return type === 'like' ? 'liked' : 'disliked';
    }
    return '';
  };

  const toggleCommentsVisibility = (id) => {
    setShowCommentsMap((prevMap) => ({
      ...prevMap,
      [id]: !prevMap[id] || false,
    }));
  };

  const getPublications = useCallback(async () => {
    setIsLoading(true);
    try {
      const response = await axios.get('https://us-central1-hormiga-631f9.cloudfunctions.net/getAnuncio', {
        params: { ...searchFields },
      });

      const dataWithVisibility = response.data.map(pub => ({
        ...pub,
        showPhone: false,
        averageRating: pub.averageRating || 0,
        ratingCount: pub.ratingCount || 0,
      }));

      const initialInteractions = dataWithVisibility.reduce((acc, pub) => {
        acc[pub.id] = pub.userInteraction;
        return acc;
      }, {});
      setUserInteractions(initialInteractions);
      setPublications(dataWithVisibility);
      setFilteredPublications(dataWithVisibility);
    } catch (error) {
      console.error('Error fetching publications:', error);
    } finally {
      setIsLoading(false);
    }
  }, [searchFields]);

  const clearFields = () => {
    setSearchFields({
      city: '',
      province: '',
      country: '',
      phone: '',
      maxLoadValue: '',
      destinationCity: '',
      destinationProvince: '',
      destinationCountry: '',
      option: '',
      reward: '',
    });
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setSearchFields((prevState) => ({ ...prevState, [name]: value }));
  };

  const filterByUser = (userName) => {
    setFilteredPublications(publications.filter(pub => pub.userName === userName));
    setFilter('user');
  };

  return (
    <div className="publications-page">
      <div className="search-form">
        <div>
          {isLoading && (
            <div className="loading-animation">
              <div className="spinner"></div>
            </div>
          )}
        </div>
        <span>Filtro de búsqueda: </span> <hr />
        <span>Origen:</span> <br />
        <input className='input' name="city" type="text" placeholder="Ciudad" value={searchFields.city} onChange={handleChange} />
        <input className='input' name="province" type="text" placeholder="Provincia" value={searchFields.province} onChange={handleChange} />
        <input className='input' name="country" type="text" placeholder="País" value={searchFields.country} onChange={handleChange} />
        <hr />
        <span>Destino:</span> <br />
        <input className='input' name="destinationCity" type="text" placeholder="Ciudad" value={searchFields.destinationCity} onChange={handleChange} />
        <input className='input' name="destinationProvince" type="text" placeholder="Provincia" value={searchFields.destinationProvince} onChange={handleChange} />
        <input className='input' name="destinationCountry" type="text" placeholder="País" value={searchFields.destinationCountry} onChange={handleChange} />
        <hr />
        <span>Opción:</span> <br />
        <div className="radio-group">
          <div className="radio-label">
            <input type="radio" id="sendOption" name="option" value="Envía" className="radio-input" checked={searchFields.option === 'Envía'} onChange={handleChange} />
            <label htmlFor="sendOption" className="radio-label">Remitente</label>
          </div>
          <div className="radio-label">
            <input type="radio" id="carryOption" name="option" value="Transporta" className="radio-input" checked={searchFields.option === 'Transporta'} onChange={handleChange} />
            <label htmlFor="carryOption" className="radio-label">Transportista</label>
          </div>
        </div>
        <button onClick={getPublications}>Buscar</button>
        <button onClick={clearFields}>Resetear</button>
      </div>
      <div className="filter-buttons">
        <button className='button' onClick={() => { setFilteredPublications(publications); setFilter('all'); }}>Todas las publicaciones </button>
        <button className='button' onClick={() => { setFilteredPublications(publications.filter(pub => pub.userName === auth.currentUser?.displayName)); setFilter('own'); }}>Mis publicaciones</button>
      </div>
      <div className="card-container">
        {filteredPublications.map(pub => (
          <div className="card" key={pub.id}>
            <div className='user-date'>
              <span>Usuario: <span style={{ color: '#F0B90B' }}>{pub.userName} <StarRating rating={parseFloat(pub.averageRating) || 0} /></span>
                <br></br>
                <span>
                  Ha recibido{' '}{pub.ratingCount || 'N/A'}{' '} {pub.ratingCount === 1 ? 'calificación' : 'calificaciones'} y su puntaje promedio es de{' '}
                  {pub.averageRating ? pub.averageRating.toFixed(1) : 'N/A'}
                </span>
                <br />
                <button className='buton-telefono' onClick={() => toggleRatingButtonsVisibility(pub.id)}>Calificar</button>
                {showRatingButtons[pub.id] && <StarRatingButtons userId={pub.userId} />}
              </span>
              <br />
              <span><span style={{ color: '#00bfff' }}>{showPhoneNumbers[pub.id] ? pub.phone : ""}</span>
                <button className='buton-telefono' onClick={() => togglePhoneVisibility(pub.id)}>Ver teléfono</button>
              </span>
              <div className='user-travel'>
                <span>Origen: {pub.city}, {pub.province}, {pub.country}</span>
                <span>Destino: {pub.destinationCity}, {pub.destinationProvince}, {pub.destinationCountry}</span>
                <span>Salida: {pub.departureDate} </span>
                <span>Llegada: {pub.arrivalDate} </span>
                <span>Descripción: {pub.descriptionItem}</span>
                <span>Valor de carga max: <span style={{ color: '#00bfff' }}>{pub.maxLoadValue}</span></span>
                <span>Recompensa: <span style={{ color: '#85bb65' }}>{pub.reward}</span></span>
                <span>Acción: <span style={{ color: '#F0B90B' }}>{pub.option}</span></span>
              </div>
            </div>
            <div className="interaction-buttons">
              <button
                className={`like-button ${getButtonClassName(pub.id, 'like')}`}
                onClick={() => handleLikeAnuncio(pub.id, true, userInteractions, setFilteredPublications, setUserInteractions, setNotificationMessage)}
              >
                👍 {pub.likes}
              </button>
              <button
                className={`dislike-button ${getButtonClassName(pub.id, 'dislike')}`}
                onClick={() => handleLikeAnuncio(pub.id, false, userInteractions, setFilteredPublications, setUserInteractions, setNotificationMessage)}
              >
                👎 {pub.dislikes}
              </button>
            </div>
            <div className="button-container">
              {filter === 'own' && auth.currentUser && pub.userName === auth.currentUser.displayName && (
                <button className='delete-button' onClick={() => handleDelete(pub.id, getPublications, setNotificationMessage, setIsLoading)}>Borrar publicación</button>
              )}
              <button className='button2' onClick={() => { toggleCommentsVisibility(pub.id); fetchComments(pub.id, setComments, setIsLoading); }}>
                {showCommentsMap[pub.id] ? 'Ocultar comentarios' : 'Ver comentarios'}
              </button>
              <button className='button2' onClick={() => filterByUser(pub.userName)}>Anuncios del usuario</button>
              <button
                className={`copy-enlace ${isCopying ? 'copying' : ''}`}
                onClick={() => copyToClipboard(pub.id)}
                title='Copiar enlace'
              >
                <img src={Viculado} alt="Copiar enlace" style={{ width: '25px', height: '25px' }} />
              </button>
              <button
                className="copy-enlace"
                onClick={() => openLinkInNewTab(pub.id)}
                title='Abrir en nueva pestaña'
              >
                <img src={Abrir} alt="Abrir nueva pestaña" style={{ width: '25px', height: '25px' }} />
              </button>
            </div>
            <div className="comment-section">
              {showCommentsMap[pub.id] && comments[pub.id] && comments[pub.id].map(comment => (
                <div key={comment.id} className="comment">
                  <p className="comment-text"><strong style={{ color: '#F0B90B' }}>{comment.user}:</strong> {comment.text}</p>
                  <p className="comment-details"><small>Escrito el {comment.timestamp}</small></p>
                  <div className="interaction-buttons">
                    <button
                      className={`like-button ${getButtonClassName(comment.id, 'like')}`}
                      onClick={() => handleLikeComentario(pub.id, comment.id, true, setComments, userCommentInteractions, setUserCommentInteractions, setNotificationMessage)}
                    >
                      👍 {comment.likes}
                    </button>
                    <button
                      className={`dislike-button ${getButtonClassName(comment.id, 'dislike')}`}
                      onClick={() => handleLikeComentario(pub.id, comment.id, false, setComments, userCommentInteractions, setUserCommentInteractions, setNotificationMessage)}
                    >
                      👎 {comment.dislikes}
                    </button>
                  </div>
                  {auth.currentUser && comment.userId === auth.currentUser.uid && (
                    <button className='delete-button' onClick={() => handleDeleteComment(pub.id, comment.id, setComments, setNotificationMessage, setIsLoading)}>Borrar comentario</button>
                  )}
                </div>
              ))}
              <div className='chat-container'>
                <textarea
                  className='input-comentarios'
                  type="text"
                  maxLength="300"
                  placeholder="Deja un comentario..."
                  value={commentText}
                  onChange={(e) => setCommentText(e.target.value)}
                  required
                  disabled={isSubmitting}
                />
                <button
                  className='Send'
                  onClick={() => {
                    setIsSubmitting(true);
                    handleComment(pub.id, commentText, setCommentText, fetchComments, setNotificationMessage, setIsSubmitting, setIsLoading)
                      .then(() => setIsSubmitting(false))
                      .catch(() => setIsSubmitting(false));
                  }}
                  disabled={isSubmitting}
                >
                  {isSubmitting ? 'Enviando...' : 'Enviar'}
                </button>
              </div>
            </div>
          </div>
        ))}
      </div>
      {notificationMessage &&
        <div className="alert" onClick={() => setNotificationMessage('')}>
          {notificationMessage}
        </div>
      }
    </div>
  );
}

export default PublicationsList;
