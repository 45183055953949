import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faStar } from '@fortawesome/free-solid-svg-icons';
import axios from 'axios';
import './../../style/components/publications.scss';
import { auth } from './../../firebase.js'; // Ajusta la ruta según tu estructura de archivos

const StarRatingButtons = ({ userId }) => {
    const [notificationMessage, setNotificationMessage] = useState('');
    const stars = [1, 2, 3, 4, 5];

    const sendUserRating = async (ratedUserId, rating) => {
        try {
            const token = await auth.currentUser.getIdToken();
            const recaptchaToken = await window.grecaptcha.execute('6LfMfAsqAAAAAJlMEX16BxL8_PU0CIC-vMlyTsvZ', { action: 'submit' });

            await axios.post('https://us-central1-hormiga-631f9.cloudfunctions.net/rateUser', {
                ratedUserId,
                rating,
                recaptchaToken
            }, {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            });

            // Actualiza el estado del mensaje de notificación
            setNotificationMessage(<span style={{ color: '#f0b90b' }}>Calificación de {rating} estrellas enviada</span>);

        } catch (error) {
            console.error('Error al enviar la calificación:', error);
            setNotificationMessage('Hubo un error al enviar la calificación.');
        }
    };

    // Maneja el clic en una estrella
    const handleStarClick = (rating) => {
        sendUserRating(userId, rating);
    };

    // Obtiene el nombre de la clase para cada estrella
    const getStarClassName = (starValue) => {
        switch (starValue) {
            case 1:
                return 'star-red';
            case 2:
                return 'star-orange';
            case 3:
                return 'star-yellow';
            case 4:
                return 'star-light-green';
            case 5:
                return 'star-dark-green';
            default:
                return '';
        }
    };

    return (
        <div className="star-rating-container">
            {stars.map(star => (
                <button
                    key={star}
                    className={`star-button ${getStarClassName(star)}`}
                    onClick={() => handleStarClick(star)}
                >
                    <FontAwesomeIcon icon={faStar} />
                </button>
            ))}
            {notificationMessage && (
                <div className="notification-message">
                    {notificationMessage}
                </div>
            )}
        </div>
    );
};

export default StarRatingButtons;
