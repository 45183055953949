import React, { useState, useEffect, useCallback } from 'react';
import { useWeb3React } from '@web3-react/core';
import { toast } from 'react-toastify';
import { setApprovedHTC, swapHTCtoUSDT, SwapContractAddress, HormigaTokenContract, usdtContract, getFrozenHTCBalance, getFrozenUSDTBalance, swapUSDTtoHTC, setApprovedUSDT } from '../../web3';
import '../../style/pages/swap.scss';
import HTCLogo from '../../assets/images/HTC logo.png';
import USDTLogo from '../../assets/images/usdttlogo.png';
import Arrow from '../../assets/images/flecha.png';
import BigNumber from 'bignumber.js';

const SwapComponent = () => {
    const { account, library } = useWeb3React();
    const [amount, setAmount] = useState('');
    const [isApproving, setIsApproving] = useState(false);
    const [userHTCAllowance, setUserHTCAllowance] = useState('0');
    const [userUSDTAllowance, setUserUSDTAllowance] = useState('0');
    const [frozenHTCBalance, setFrozenHTCBalance] = useState('0');
    const [frozenUSDTBalance, setFrozenUSDTBalance] = useState('0');
    const [showModal, setShowModal] = useState(false);
    const [isProcessing, setIsProcessing] = useState(false);
    const [isHTCtoUSDT, setIsHTCtoUSDT] = useState(true);
    const TOKEN_DECIMALS = 6;

    const fromTokenUnits = (amount) => new BigNumber(amount).dividedBy(new BigNumber(10).pow(TOKEN_DECIMALS));
    const formatAmount = (amount, decimals = 6) => {
        if (!amount || isNaN(amount)) return '0.000000';
        return fromTokenUnits(amount).toFixed(decimals);
    };

    const fetchAllowances = useCallback(async () => {
        try {
            const htcAllowance = await HormigaTokenContract.methods.allowance(account, SwapContractAddress).call();
            setUserHTCAllowance(fromTokenUnits(htcAllowance).toString());

            const usdtAllowance = await usdtContract.methods.allowance(account, SwapContractAddress).call();
            setUserUSDTAllowance(fromTokenUnits(usdtAllowance).toString());
        } catch (error) {
            console.error('Error fetching allowances:', error);
        }
    }, [account]);

    useEffect(() => {
        if (account && library) {
            fetchAllowances();
            const fetchFrozenBalances = async () => {
                try {
                    const htcBalance = await getFrozenHTCBalance();
                    const usdtBalance = await getFrozenUSDTBalance();
                    setFrozenHTCBalance(htcBalance);
                    setFrozenUSDTBalance(usdtBalance);
                } catch (error) {
                    console.error('Error fetching frozen balances:', error);
                }
            };
            fetchFrozenBalances();
        }
    }, [account, library, fetchAllowances]);

    const approveToken = async () => {
        try {
            setIsApproving(true);
            if (isHTCtoUSDT) {
                await setApprovedHTC(amount, account);
                toast.success('HTC aprobado para gastar.');
            } else {
                await setApprovedUSDT(amount, account);
                toast.success('USDT aprobado para gastar.');
            }
            fetchAllowances();
        } catch (error) {
            console.error('Error al aprobar token:', error);
            toast.error('Error al aprobar token. Intente nuevamente más tarde.');
        } finally {
            setIsApproving(false);
        }
    };

    const swapToken = async () => {
        try {
            setIsProcessing(true);
            if (isHTCtoUSDT) {
                await swapHTCtoUSDT(amount, account, library);
                toast.success('Operación de intercambio HTC a USDT realizada exitosamente.');
            } else {
                await swapUSDTtoHTC(amount, account, library);
                toast.success('Operación de intercambio USDT a HTC realizada exitosamente.');
            }
        } catch (error) {
            console.error('Error al intercambiar token:', error);
            toast.error('Error al intercambiar token. Intente nuevamente más tarde.');
        } finally {
            setIsProcessing(false);
        }
    };

    const handleConfirmSwap = () => {
        swapToken();
        setShowModal(false);
    };

    const calculateReceivedTokens = (amount) => {
        const receivedAmount = new BigNumber(amount);
        if (receivedAmount.isNaN()) return '0.000000';
        return formatAmount(receivedAmount.toString(), TOKEN_DECIMALS);
    };

    const handleInputChange = (e) => {
        const value = e.target.value;
        if (value === '' || !isNaN(value)) {
            setAmount(value);
        }
    };

    const toggleSwapDirection = () => {
        setIsHTCtoUSDT(!isHTCtoUSDT);
        setAmount('');
    };

    return (
        <div className="swap">
            <h2>Intercambio de tokens</h2>
            <button className="toggle-button" onClick={toggleSwapDirection}>
                {isHTCtoUSDT ? "↓↑" : "↑↓"}
            </button>
           
            <div className="input-box">
                <label className="input-label">
                    <img src={isHTCtoUSDT ? HTCLogo : USDTLogo} alt="Token Logo" className="token-logo" />
                    <img src={Arrow} alt="Arrow" className="arrow-logo" />
                    <img src={isHTCtoUSDT ? USDTLogo : HTCLogo} alt="Token Logo" className="token-logo" />
                </label>
                <input className="input" placeholder={`Ingresa cantidad en ${isHTCtoUSDT ? 'HTC' : 'USDT'}`} type="number" value={amount} onChange={handleInputChange} />
            </div>
            <div>
                <p>Recibirás {calculateReceivedTokens(amount || '0')} {isHTCtoUSDT ? 'USDT' : 'HTC'}.</p>
            </div>
            <div className="allowance-info">
                Tokens {isHTCtoUSDT ? 'HTC' : 'USDT'} aprobados para Intercambiar: {isHTCtoUSDT ? userHTCAllowance : userUSDTAllowance}
            </div>
            <button className="component-button" onClick={approveToken} disabled={isApproving}>
                Aprobar {isHTCtoUSDT ? 'HTC' : 'USDT'}
            </button>
            <button className="component-button" onClick={() => { setShowModal(true); }} disabled={isProcessing}>
                Intercambiar por {isHTCtoUSDT ? 'USDT' : 'HTC'}
            </button>
          
            <div className="frozen-balances">
                <div>Saldos congelados en contrato:</div>
                <div>HTC : {formatAmount(frozenHTCBalance)}</div>
                <div>USDT : {formatAmount(frozenUSDTBalance)}</div>
            </div>
            <div className="conversion-rate">
                <span>Tasa de conversión: 1 HTC = 1 USDT</span>
            </div>
            {showModal && (
                <div className="modal">
                    <div className="modal-content">
                        {!isProcessing && (
                            <div className="modal-buttons">
                                <div>
                                    <span>Estas cambiando {isHTCtoUSDT ? 'HTC por USDT' : 'USDT por HTC'}</span>
                                    <div className="input-box">
                                        <label className="input-label">
                                            <img src={isHTCtoUSDT ? HTCLogo : USDTLogo} alt="Token Logo" className="token-logo" />
                                            <img src={Arrow} alt="Arrow" className="arrow-logo" />
                                            <img src={isHTCtoUSDT ? USDTLogo : HTCLogo} alt="Token Logo" className="token-logo" />
                                        </label>
                                    </div>
                                    <div>
                                        <p>Recibirás {calculateReceivedTokens(amount)} {isHTCtoUSDT ? 'USDT' : 'HTC'}.</p>
                                    </div>
                                    <button className="component-button-verde" onClick={handleConfirmSwap}>Confirmar</button>
                                    <button className="component-button-rojo" onClick={() => setShowModal(false)}>Cancelar</button>
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            )}
        </div>
    );
};

export default SwapComponent;
