import React, { useState, useEffect, useCallback } from 'react';
import axios from 'axios';
import { auth } from './../../firebase.js';
import './../../style/pages/inbox.scss';

const Inbox = () => {
    const [messages, setMessages] = useState([]);
    const [selectedMessage, setSelectedMessage] = useState(null);
    const [replyText, setReplyText] = useState('');
    const [notificationMessage, setNotificationMessage] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [authReady, setAuthReady] = useState(false);
    const [view, setView] = useState('received');
    const [loadingMessageId, setLoadingMessageId] = useState(null);

    const fetchMessages = useCallback(async () => {
        if (!authReady) return;

        setIsLoading(true);
        try {
            const user = auth.currentUser;
            if (!user) {
                setNotificationMessage('Por favor, inicia sesión para ver los mensajes.');
                setIsLoading(false);
                return;
            }
            const token = await user.getIdToken(true);

            const response = await axios.get('https://us-central1-hormiga-631f9.cloudfunctions.net/getMessages', {
                headers: {
                    Authorization: `Bearer ${token}`
                },
                params: {
                    userId: user.uid,
                    view: view
                }
            });

            const formattedMessages = response.data.map(message => ({
                ...message,
                sentAt: new Date(message.sentAt).toLocaleString(),
                replies: message.replies?.map(reply => ({
                    ...reply,
                    sentAt: new Date(reply.sentAt).toLocaleString()
                })) || []
            }));

            setMessages(formattedMessages || []);
        } catch (error) {
            console.error('Error fetching messages:', error.response?.data || error.message);
            setNotificationMessage(`Error al obtener los mensajes: ${error.response?.data?.error || 'Error desconocido'}`);
        } finally {
            setIsLoading(false);
        }
    }, [authReady, view]);

    const fetchReplies = async (messageId) => {
        setLoadingMessageId(messageId);
        try {
            const user = auth.currentUser;
            if (!user) {
                setNotificationMessage('Por favor, inicia sesión para ver los mensajes.');
                setLoadingMessageId(null);
                return;
            }
            const token = await user.getIdToken(true);

            const response = await axios.get('https://us-central1-hormiga-631f9.cloudfunctions.net/getMessages', {
                headers: {
                    Authorization: `Bearer ${token}`
                },
                params: {
                    userId: user.uid,
                    messageId: messageId
                }
            });

            const updatedMessage = response.data.find(message => message.id === messageId);
            if (updatedMessage) {
                setSelectedMessage(prevMessage => ({
                    ...prevMessage,
                    replies: updatedMessage.replies.map(reply => ({
                        ...reply,
                        sentAt: new Date(reply.sentAt).toLocaleString()
                    }))
                }));
            }
        } catch (error) {
            console.error('Error fetching replies:', error.response?.data || error.message);
            setNotificationMessage(`Error al obtener las respuestas: ${error.response?.data?.error || 'Error desconocido'}`);
        } finally {
            setLoadingMessageId(null);
        }
    };

    const handleReply = async () => {
        if (isSubmitting || replyText.trim() === '') {
            setNotificationMessage('La respuesta no puede estar vacía.');
            return;
        }

        setIsSubmitting(true);
        setLoadingMessageId(selectedMessage?.id);

        try {
            const user = auth.currentUser;
            if (!user) {
                setNotificationMessage('Por favor, inicia sesión para responder.');
                setIsSubmitting(false);
                setLoadingMessageId(null);
                return;
            }

            const token = await user.getIdToken(true);
            const messageId = selectedMessage?.id;
            if (!messageId) {
                setNotificationMessage('El ID del mensaje no está disponible.');
                setIsSubmitting(false);
                setLoadingMessageId(null);
                return;
            }

            // Obtener el token de reCAPTCHA
            const recaptchaToken = await window.grecaptcha.execute('6LfMfAsqAAAAAJlMEX16BxL8_PU0CIC-vMlyTsvZ', { action: 'submit' });

            await axios.post('https://us-central1-hormiga-631f9.cloudfunctions.net/replyToMessage', {
                messageId,
                replyText,
                recaptchaToken
            }, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            });

            const newReply = {
                senderUserId: user.uid,
                senderUserName: user.displayName,
                replyText,
                sentAt: new Date().toISOString()
            };

            setSelectedMessage(prevMessage => ({
                ...prevMessage,
                replies: [...prevMessage.replies, {
                    ...newReply,
                    sentAt: new Date(newReply.sentAt).toLocaleString()
                }]
            }));
            setReplyText('');
            fetchMessages();
        } catch (error) {
            console.error('Error al enviar la respuesta:', error.response?.data || error.message);
            setNotificationMessage('Error al enviar la respuesta. Intenta nuevamente.');
        } finally {
            setIsSubmitting(false);
            setLoadingMessageId(null);
        }
    };

    const handleDelete = async (messageId) => {
        if (isSubmitting) return;

        setIsSubmitting(true);
        setLoadingMessageId(messageId);

        try {
            const user = auth.currentUser;
            if (!user) {
                setNotificationMessage('Por favor, inicia sesión para eliminar mensajes.');
                setIsSubmitting(false);
                setLoadingMessageId(null);
                return;
            }

            const token = await user.getIdToken(true);

            // Obtener el token de reCAPTCHA
            const recaptchaToken = await window.grecaptcha.execute('6LfMfAsqAAAAAJlMEX16BxL8_PU0CIC-vMlyTsvZ', { action: 'submit' });

            await axios.post('https://us-central1-hormiga-631f9.cloudfunctions.net/deleteMessage', {
                messageId,
                recaptchaToken
            }, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            });

            setNotificationMessage('Mensaje eliminado con éxito');
            setSelectedMessage(null);
            fetchMessages();
        } catch (error) {
            setNotificationMessage('Error al eliminar el mensaje. Intenta nuevamente.');
        } finally {
            setIsSubmitting(false);
            setLoadingMessageId(null);
        }
    };

    useEffect(() => {
        const unsubscribe = auth.onAuthStateChanged(user => {
            if (user) {
                setAuthReady(true);
                fetchMessages();
            } else {
                setAuthReady(false);
                setIsLoading(false);
                setNotificationMessage('Por favor, inicia sesión para ver los mensajes.');
            }
        });
        return () => unsubscribe();
    }, [fetchMessages]);

    const filteredMessages = messages.filter(message =>
        view === 'received' ? message.recipientUserName === auth.currentUser?.displayName : message.senderUserName === auth.currentUser?.displayName
    );

    const toggleMessageSelection = (message) => {
        if (selectedMessage && selectedMessage.id === message.id) {
            setSelectedMessage(null);
        } else {
            setSelectedMessage(message);
            setReplyText('');
        }
    };

    return (
        <div className="inbox-container">
            {isLoading ? (
                <div className="loading-animation">
                    <div className="spinner"></div>
                </div>
            ) : (
                <div className="inbox-content">
                    <h1 className="inbox-title">Bandeja de Entrada</h1>
                    {notificationMessage && (
                        <div className="alert" onClick={() => setNotificationMessage('')}>
                            {notificationMessage}
                        </div>
                    )}
                    <div className="view-buttons">
                        <button onClick={() => setView('received')} className={view === 'received' ? 'active' : ''}>
                            Mensajes Recibidos
                        </button>
                        <button onClick={() => setView('sent')} className={view === 'sent' ? 'active' : ''}>
                            Mensajes Enviados
                        </button>
                    </div>
                    <div className="message-list">
                        {filteredMessages.length === 0 ? (
                            <p>No tienes mensajes.</p>
                        ) : (
                            filteredMessages.map(message => (
                                <div
                                    key={message.id}
                                    className={`message-item ${selectedMessage && selectedMessage.id === message.id ? 'selected' : ''}`}
                                    onClick={(e) => {
                                        if (!e.target.classList.contains('reply-textarea')) {
                                            toggleMessageSelection(message);
                                        }
                                    }}
                                >
                                    <div className="message-header">
                                        <strong>{message.senderUserName === auth.currentUser?.displayName ? 'Para:' : 'De:'}</strong>
                                        {message.senderUserName === auth.currentUser?.displayName ? message.recipientUserName : message.senderUserName}
                                    </div>
                                    <div className="message-body">
                                        <strong>Mensaje:</strong> {message.messageText}
                                    </div>
                                    <div className="message-footer">
                                        <small>Enviado el {message.sentAt}</small>
                                        <button
                                            className="delete-button2"
                                            onClick={(e) => {
                                                e.stopPropagation();
                                                handleDelete(message.id);
                                            }}
                                            disabled={isSubmitting && loadingMessageId === message.id}
                                        >
                                            {isSubmitting && loadingMessageId === message.id ? <div className="spinner"></div> : 'Eliminar'}
                                        </button>
                                    </div>

                                    {selectedMessage && selectedMessage.id === message.id && (
                                        <div className="message-details">
                                            {selectedMessage.replies && selectedMessage.replies.length > 0 && (
                                                <div className="replies">
                                                    <h3>Respuestas:</h3> <hr />
                                                    {selectedMessage.replies.map((reply, index) => (
                                                        <div key={index} className={`reply-item ${reply.senderUserId === auth.currentUser?.uid ? 'reply-user' : 'reply-contraparte'}`}>
                                                            <p><strong>{reply.senderUserId === auth.currentUser?.uid ? 'Tu' : 'Contraparte'}:</strong> {reply.replyText}</p>
                                                            <small className="message-footer">Enviado el {reply.sentAt}</small>
                                                        </div>
                                                    ))}
                                                </div>
                                            )}

                                            <div className="reply-section" onClick={(e) => e.stopPropagation()}>
                                                <textarea
                                                    className='reply-textarea card'
                                                    value={replyText}
                                                    onChange={(e) => setReplyText(e.target.value)}
                                                    placeholder="Escribe tu respuesta..."
                                                    maxLength="250"
                                                ></textarea>
                                                <br />
                                                <button 
                                                    className='reply-button' 
                                                    onClick={replyText.trim() ? handleReply : () => fetchReplies(selectedMessage.id)} 
                                                    disabled={isSubmitting && loadingMessageId === selectedMessage.id}
                                                >
                                                    {isSubmitting && loadingMessageId === selectedMessage.id ? <div className="spinner"></div> : replyText.trim() ? 'Responder' : 'Refrescar'}
                                                </button>
                                            </div>
                                        </div>
                                    )}
                                </div>
                            ))
                        )}
                    </div>
                </div>
            )}
        </div>
    );
};

export default Inbox;
