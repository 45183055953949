import React, { useEffect, useState } from "react";
import { getRemitoById, deliver, claimRefund, transferNFT, requestAccess, getOwnerOfNFT, getTokenURI } from "../web3/index";
import backupImage from "./../assets/images/boxuniverse500.png";
import {  toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


const NFTItem = ({ tokenId, filterType }) => {
  const [remitoData, setRemitoData] = useState(null);
  const [transferAddress, setTransferAddress] = useState("");
  const [miAddress, setMiAddress] = useState("");
  const [currentOwner, setCurrentOwner] = useState("");
  const [tokenURI, setTokenURI] = useState("");
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [remainingTime, setRemainingTime] = useState("");
  const [timeColor, setTimeColor] = useState("");
  const [showLoader, setShowLoader] = useState(false); // Nuevo estado para controlar la animación de carga

  const compareAddresses = (address1, address2) => {
    if (!address1 || !address2) return false;
    return address1.toLowerCase() === address2.toLowerCase();
  };

  useEffect(() => {
    const initialize = async () => {
      const address = await requestAccess();
      setMiAddress(address);

      const data = await getRemitoById(tokenId);
      setRemitoData(data);

      const owner = await getOwnerOfNFT(tokenId);
      setCurrentOwner(owner);

      try {
        const uri = await getTokenURI(tokenId);
        setTokenURI(uri);
      } catch (err) {
        setError(err.message || "Error fetching token URI.");
      } finally {
        setLoading(false);
      }
    };

    initialize();
  }, [tokenId]);

  useEffect(() => {
    if (remitoData && !remitoData.entregado) {
      const tiempoLimiteDate = new Date(remitoData.tiempoLimite * 1000);
      const currentTime = new Date();

      if (tiempoLimiteDate > currentTime) {
        const tiempoRestante = tiempoLimiteDate - currentTime;
        const diasRestantes = Math.floor(
          tiempoRestante / (1000 * 60 * 60 * 24)
        );
        const horasRestantes = Math.floor(
          (tiempoRestante % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
        );
        const minutosRestantes = Math.floor(
          (tiempoRestante % (1000 * 60 * 60)) / (1000 * 60)
        );

        if (diasRestantes >= 30) {
          setTimeColor("goldenrod");
        } else if (diasRestantes >= 7) {
          setTimeColor("green");
        } else if (diasRestantes >= 1) {
          setTimeColor("white");
        } else {
          if (diasRestantes === 0) {
            setTimeColor("red");
            setRemainingTime("Tiempo agotado, puedes reclamar el reembolso");
          } else {
            setTimeColor("#f94b17");
            setRemainingTime(
              `Time out, you can claim a refund at ${diasRestantes} days`
            );
          }
        }

        setRemainingTime(
          `Tiempo restante: ${diasRestantes} dias ${horasRestantes} horas ${minutosRestantes} minutos`
        );
      } else {
        setRemainingTime("Tiempo agotado!!! El address declarado como cartera liberadora puede reclamar los fondos, al hacerlo los tokens se transferirán a la dirección especificada.");
        setTimeColor("red");
      }
    }
  }, [remitoData]);

  const handleAction = async (actionType) => {
    setShowLoader(true); // Mostrar animación de carga al iniciar la acción
  
    try {
      switch (actionType) {
        case "deliver":
          await deliver(tokenId);
          setRemitoData((prevData) => ({ ...prevData, entregado: true }));
          toast.success('Fondos liberados exitosamente.');
          break;
        case "claimRefund":
          await claimRefund(tokenId);
          setRemitoData((prevData) => ({ ...prevData, refundClaimed: true }));
          toast.success('Reembolso realizado exitosamente.');
          break;
        case "transferNFT":
          await transferNFT(tokenId, transferAddress);
          setCurrentOwner(transferAddress);
          toast.success('NFT transferido exitosamente.');
          break;
        default:
          console.error(`Action type "${actionType}" is not recognized.`);
          toast.error('Acción no reconocida.');
          break;
      }
    } catch (error) {
      console.error("Error executing action:", error);
      toast.error('Error al ejecutar la acción. Por favor, intenta de nuevo.');
    } finally {
      setShowLoader(false); // Ocultar animación de carga al completar la acción
    }
  };
  
  
  
  return (
    <div className="nft-card">
      <h1>Remito: {tokenId}</h1>
      <div className="body">
        {showLoader && (
          <div className="loading-animation">
             <div className="spinner"></div>
          </div>
        )}
  
        {loading ? (
          <div>Loading...</div>
        ) : error ? (
          <div>Error: {error}</div>
        ) : (
          <>
            {tokenURI ? (
              <img
                src={`https://ipfs.io/ipfs/${tokenURI}`}
                alt={`NFT ${tokenId}`}
              />
            ) : (
              <img src={backupImage} alt={`NFT ${tokenId}`} />
            )}
          </>
        )}
  
        <div className="text-content">
          <p>Valor declarado: {remitoData?.valorDeclarado}</p>
          <p>Recompensa: {remitoData?.recompensa}</p>
          <p>Tiempo limite: {new Date(remitoData?.tiempoLimite * 1000).toLocaleString()}</p>
          <p>Origen: {remitoData?.origen}</p>
          <p>Destino: {remitoData?.destino}</p>
          <p>Cartera-liberadora: {remitoData?.liberatingWallet}</p>
          <p style={{ color: timeColor }}>{remainingTime}</p>

          {remitoData?.entregado && (
            <h3 style={{ color: "#3c9e28", fontFamily: "Arial " }}>
              Liberado! Fondos transferidos al portador del NFT
            </h3>
          )}
          {remitoData?.refundClaimed && (
            <h3 style={{ color: "#f94b17", fontFamily: "Arial" }}>
              Reembolsado! Fondos transferidos a la cartera-liberadora.
            </h3>
          )}

          {(filterType === "own" || filterType === "created" || filterType === "liberating") && (
            <>
              {!remitoData?.entregado && !remitoData?.refundClaimed && compareAddresses(miAddress, remitoData?.liberatingWallet)&& (
                <>
                  <button
                    className="component-button liberar-button"
                    onClick={() => handleAction("deliver")}
                  >
                    Liberar fondos
                  </button>
                  <button
                    className="component-button reembolsar-button"
                    onClick={() => handleAction("claimRefund")}
                  >
                    Reembolsar fondos
                  </button>
                </>
              )}

              {!remitoData?.entregado && !remitoData?.refundClaimed && compareAddresses(miAddress, currentOwner) && (
                <>
                  <input
                    type="text"
                    placeholder="Transferir a cartera 0x..."
                    value={transferAddress}
                    onChange={(e) => setTransferAddress(e.target.value)}
                    className="address-input"
                  />
                  <button
                    className="component-button transfer-button"
                    onClick={() => handleAction("transferNFT")}
                  >
                    Transferir
                  </button>
                </>
              )}
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default NFTItem;
